app.factory('exception', [
    'utils',
    function (
        utils,
    ) {
        const HOSTNAME_REGEX = /http[s]*:\/\/[^/]+.*?/ig;
        const URL_REGEX = /http[s]?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/ig;
        const UUID_REGEX = /[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}/g;
        const DATETIME_REGEX = /[0-9]{4}_[0-9]{2}_[0-9]{2}_[0-9]{6}/g;
        const ISO_DATETIME_REGEX = /[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}(.[0-9]{3})?Z?/g;
        const DATE_REGEX = /[0-9]{4}-[0-9]{2}-[0-9]{2}/g;
        const TIME_REGEX = /[0-9]{2}:[0-9]{2}:[0-9]{2}/g;
        const ORDER_ID_REGEX = /\b[0-9][0-9][0-9][0-9]+/g;
        const DEVICE_TOKEN_REGEX = /[a-zA-Z0-9]{9}-[a-zA-Z0-9]{9}-[a-zA-Z0-9]{9}-[a-zA-Z0-9]{9}/ig;
        const SHA1_HASH_REGEX = /\b[a-f0-9]{40}\b/ig;
        const MD5_HASH_REGEX = /\b[a-f0-9]{32}\b/ig;
        const BEARER_TOKEN_REGEX = /\bBearer\s[a-z0-9.-]+/gi;

        return {
            normalise (message) {
                message = message.replace(UUID_REGEX, '(uuid)');
                message = message.replace(DATETIME_REGEX, '(date/time)');
                message = message.replace(ISO_DATETIME_REGEX, '(iso-date/time)');
                message = message.replace(DATE_REGEX, '(date)');
                message = message.replace(TIME_REGEX, '(time)');
                message = message.replace(DEVICE_TOKEN_REGEX, '(device_token)');
                message = message.replace(SHA1_HASH_REGEX, '(sha1_hash)');
                message = message.replace(MD5_HASH_REGEX, '(md5_hash)');
                message = message.replace(ORDER_ID_REGEX, '#');
                message = message.replace(BEARER_TOKEN_REGEX, '(bearer_token)');
                message = message.replace(URL_REGEX, (matches) => {
                    const path = matches.replace(HOSTNAME_REGEX, '');
                    const hostname = matches.replace(path, '/(url)');
                    return hostname;
                });

                return utils.cleanString(message);
            },

            report (msg, error) {
                if (typeof error !== 'string') {
                    try {
                        error = JSON.stringify(error);
                    } catch (e) {
                        console.error('Failed to stringify error', error);
                    }
                }

                dataLayer.push({
                    event: 'Unhandled error',
                    action: msg + ':' + this.normalise(error),
                    label: msg + ':' + error,
                });
            },
        };
    },
]);
