app.factory('CartReorderImage', [
    function (
    ) {
        return class CartReorderImage {
            constructor(key, thumbnailUrl, imageId) {
                this.key = key;
                this.thumbnailUrl = thumbnailUrl;
                this.imageId = imageId;
            }

            isReady() {
                return true;
            }

            isStarted() {
                return true;
            }

            isErrored() {
                return false;
            }

            getProgress() {
                return 1;
            }

            isCanvas() {
                return false;
            }

            isUpload() {
                return false;
            }

            canEnlarge() {
                return false;
            }

            getImageId() {
                return this.imageId;
            }

            getSrc() {
                return this.thumbnailUrl;
            }
        };
    },
]);
