app.factory('Debouncer', [
    '$timeout',
    function (
        $timeout,
    ) {
        return class {
            constructor(delay = 100) {
                this.timeout = null;
                this.delay = delay;
            }
            trigger(callback) {
                if (this.timeout) {
                    $timeout.cancel(this.timeout);
                }
                this.timeout = $timeout(() => {
                    this.timeout = null;
                    callback();
                }, this.delay);
            }
        };
    },
]);
