app.factory('apiv2', [
    '$q',
    '$http',
    'config',
    'log',
    'login',
    function (
        $q,
        $http,
        config,
        log,
        login,
    ) {
        return new class ApiV2 {
            constructor() {
                if (config.api.printicular.environment == 'production') {
                    this.apiUrl = 'https://api.printicular.com/';
                } else if (config.api.printicular.environment == 'production-cache') {
                    this.apiUrl = 'https://capi.printicular.com/';
                } else if (config.api.printicular.environment == 'staging') {
                    this.apiUrl = 'https://stagingapi.printicular.com/';
                } else if (config.api.printicular.environment == 'staging-cache') {
                    this.apiUrl = 'https://stagingcapi.printicular.com/';
                } else {
                    this.apiUrl = config.api.printicular.url;
                }
                this.apiUrl += 'api/v2/';
                log.debug('APIv2 URL', config.api.printicular.environment, this.apiUrl);

                if (login.loginToken) {
                    this.post('client/account/resume', {
                        data: {
                            type: 'ResumeLogin',
                            attributes: {
                                deviceId: config.deviceToken,
                            },
                        },
                    }).then(() => {
                        // Success, do nothing
                    }).catch((error) => {
                        log.debug(error);
                        login.loginToken = null;
                    });
                }
            }

            request(method, url, data) {
                return $q((resolve, reject) => {
                    const loginToken = login.loginToken || config.api.printicular.clientId;
                    const headers = {
                        Authorization: 'Bearer ' + loginToken,
                    };
                    log.debug('APIv2 request', method, this.apiUrl + url, data);
                    $http({
                        method: method,
                        url: this.apiUrl + url,
                        data: data,
                        headers: headers,
                    }).then((response) => {
                        if (response.data && response.data.error) {
                            log.debug('APIv2 error', method, this.apiUrl + url, data, response);
                            reject(response.data.error);
                            return;
                        }
                        log.debug('APIv2 response', method, this.apiUrl + url, data, response);
                        resolve(response.data);
                    }, (response) => {
                        log.debug('APIv2 failure', method, this.apiUrl + url, data, response);
                        if (response.data && response.data.error) {
                            reject(response.data.error);
                        } else {
                            reject(response);
                        }
                    });
                });
            }

            get(url, parameters) {
                if (parameters) {
                    url += '?' + $.param(parameters);
                }
                return this.request('get', url);
            }

            post(url, data) {
                return this.request('post', url, data);
            }
        };
    },
]);
