app.filter('money', [
    'cart',
    'config',
    function (
        cart,
        config,
    ) {
        const currencySymbols = {
            EUR: '€', // Euro
            CRC: '₡', // Costa Rican Colón
            GBP: '£', // British Pound Sterling
            ILS: '₪', // Israeli New Sheqel
            INR: '₹', // Indian Rupee
            JPY: '¥', // Japanese Yen
            KRW: '₩', // South Korean Won
            NGN: '₦', // Nigerian Naira
            PHP: '₱', // Philippine Peso
            PLN: 'zł', // Polish Zloty
            PYG: '₲', // Paraguayan Guarani
            THB: '฿', // Thai Baht
            UAH: '₴', // Ukrainian Hryvnia
            VND: '₫', // Vietnamese Dong
            ZAR: 'R', // South African Rand
            BRL: 'R$',  // Brazilian real
        };

        return function (amount) {
            if (amount === null || amount === undefined) {
                amount = 0;
            }

            let currency = cart.getCurrency();
            let currencySymbol = '$';
            if (config.locale.currencySymbol) {
                currencySymbol = config.locale.currencySymbol;
            } else if (currencySymbols[currency]) {
                currencySymbol = currencySymbols[currency];
            }

            let result = currencySymbol + ' ' + (Math.round(amount * 100) / 100).toFixed(2);
            if (config.locale.showCurrencySuffix) {
                result += ' ' + cart.getCurrency();
            }
            return result;
        };
    },
]);
