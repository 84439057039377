app.factory('config', [
    'log',
    function (
        log,
    ) {
        return new class Config {
            constructor() {
                log.debug('Config', CONFIG);
                for (const key in CONFIG) {
                    this[key] = CONFIG[key];
                }

                const currentVersion = this.api.printicular.clientId + ' ' + this.version;
                const storageVersion = this.storageGet('version');
                if (!storageVersion || storageVersion !== currentVersion) {
                    for (let i = 0; i < localStorage.length; i++) {
                        let key = localStorage.key(i);
                        if (key.indexOf(this.localStoragePrefix + '_') === 0) {
                            localStorage.removeItem(key);
                        }
                    }
                    this.storageSet('version', currentVersion);
                }

                this.deviceToken = this.storageGet('deviceToken');
                if (!this.deviceToken) {
                    this.deviceToken = [
                        Math.random().toString(36).substr(2, 9),
                        Math.random().toString(36).substr(2, 9),
                        Math.random().toString(36).substr(2, 9),
                        Math.random().toString(36).substr(2, 9),
                    ].join('-');
                    this.storageSet('deviceToken', this.deviceToken);
                }
            }

            storageSet(key, data, prefix = true) {
                if (data === undefined) {
                    data = null;
                }
                localStorage.setItem(this.getStorageKey(key, prefix), JSON.stringify(data));
            }

            storageGet(key, prefix = true) {
                const storageKey = this.getStorageKey(key, prefix);
                try {
                    const item = localStorage.getItem(storageKey);
                    if (item === undefined) {
                        return null;
                    }
                    return JSON.parse(item);
                } catch (e) {
                    console.error('Failed to parse storage item', storageKey); /* eslint-disable-line no-console */
                    console.error(e); /* eslint-disable-line no-console */
                    localStorage.removeItem(storageKey);
                    return null;
                }
            }

            storageRemove(key, prefix = true) {
                localStorage.removeItem(this.getStorageKey(key, prefix));
            }

            getStorageKey(key, prefix = true) {
                return (prefix ? this.localStoragePrefix + '_' : '') + key;
            }

            url(path) {
                path = path.replace(/^\/+/, '');
                return $('base').attr('href') + path;
            }
        };
    },
]);
