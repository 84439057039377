app.factory('CropRectPos', [function () {
    return class CropRectPos {
        constructor() {
            this.fixedX = null;
            this.fixedY = null;
            this.floatX = null;
            this.floatY = null;
            this.rotation = 0;
            this.productSize = null;
            this.orientation = null;
        }
        get x() {
            return Math.min(this.fixedX, this.floatX);
        }

        get y() {
            return Math.min(this.fixedY, this.floatY);
        }

        get width() {
            return Math.abs(this.fixedX - this.floatX);
        }

        get height() {
            return Math.abs(this.fixedY - this.floatY);
        }

        get productWidth() {
            if (!this.productSize) {
                return null;
            }
            if (this.orientation == 'landscape') {
                return this.productSize.width > this.productSize.height ? this.productSize.width : this.productSize.height;
            }
            return this.productSize.width > this.productSize.height ? this.productSize.height : this.productSize.width;
        }

        get productHeight() {
            if (!this.productSize) {
                return null;
            }
            if (this.orientation == 'landscape') {
                return this.productSize.width > this.productSize.height ? this.productSize.height : this.productSize.width;
            }
            return this.productSize.width > this.productSize.height ? this.productSize.width : this.productSize.height;
        }

        getAspectRect() {
            let x, y, width, height;
            width = this.width;
            height = this.height;
            const minSize = 20;
            if (width < minSize) {
                width = minSize;
            }
            if (height < minSize) {
                height = minSize;
            }

            const ratio = width / height;
            const productRatio = this.productWidth / this.productHeight;
            if (ratio < productRatio) {
                height = this.productHeight / this.productWidth * width;
            } else {
                width = this.productWidth / this.productHeight * height;
            }

            if (this.fixedX < this.floatX) {
                x = this.fixedX;
            } else {
                x = this.fixedX - width;
            }

            if (this.fixedY < this.floatY) {
                y = this.fixedY;
            } else {
                y = this.fixedY - height;
            }

            return {
                x: x,
                y: y,
                width: width,
                height: height,
            };
        }

        rotate() {
            this.rotation++;
            if (this.rotation > 3) {
                this.rotation = 0;
            }
        }
    };
}]);
