app.directive('pStripeCard', [
    'config',
    '$rootScope',
    function (
        config,
        $rootScope
    ) {
        return {
            restrict: 'A',
            scope: {
            },
            link: function () {
                if (config.api.stripe.key) {
                    $rootScope.stripe = Stripe(config.api.stripe.key);
                    const elements = $rootScope.stripe.elements({
                        locale: config.language,
                    });
                    $rootScope.card = elements.create('card', {
                        hidePostalCode: true,
                        style: {
                            base: {
                                color: '#32325d',
                                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                                fontSmoothing: 'antialiased',
                                fontSize: '16px',
                                '::placeholder': {
                                    color: '#aab7c4',
                                },
                            },
                            invalid: {
                                color: '#fa755a',
                                iconColor: '#fa755a',
                            },
                        },
                    });
                    $rootScope.card.mount('#card-element');
                }
            },
        };
    },
]);
