app.factory('gtm', [
    'log',
    function (
        log,
    ) {
        return new class Gtm {
            constructor() {
                this.data = [];
            }

            push(data) {
                this.data.push(data);
                dataLayer.push(data);
                log.verbose('GTM count', this.data.length, dataLayer.length);
            }
        };
    },
]);
