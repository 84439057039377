app.factory('Price', [
    'BaseModel',
    function (
        BaseModel,
    ) {
        return class Price extends BaseModel {
            static isType(type) {
                return type == 'prices' || type == 'price';
            }
        };
    },
]);
