app.factory('CartImageData', [
    'CartImage',
    function (
        CartImage,
    ) {
        return class CartImageData extends CartImage {
            constructor(key, data, upload) {
                super(key, null, upload, null);
                this.data = data;
            }

            isErrored() {
                return this.upload.error;
            }
        };
    },
]);
