app.factory('Image', [
    'BaseModel',
    function (
        BaseModel,
    ) {
        return class Image extends BaseModel {
            static isType(type) {
                return type == 'images' || type == 'image';
            }

            static createInstance(id, type, attributes, relationships, included, meta) {
                const image = super.createInstance(id, type, attributes, relationships, included, meta);
                return image;
            }
        };
    },
]);
