app.directive('pAutoSizeImg', [
    'log',
    'cart',
    function (
        log,
        cart,
    ) {
        const size = 200;
        return {
            restrict: 'A',
            scope: {
                lineItem: '=pAutoSizeImg',
            },
            link: function (scope, element) {
                const resize = () => {
                    const squareImageAutoCrop = cart.getPrintServiceSettings().squareImageAutoCrop || 'landscape';
                    const image = scope.lineItem.image.thumbnail && scope.lineItem.image.thumbnail.image;
                    const product = scope.lineItem.product;

                    if (image && product) {
                        const imageRatio = scope.lineItem.image.thumbnail.width / scope.lineItem.image.thumbnail.height;
                        const productSize = scope.lineItem.product.getProductSize();

                        let cropOrientation = null;
                        if (imageRatio == 1) {
                            cropOrientation = squareImageAutoCrop;
                        } else if (imageRatio > 1) {
                            cropOrientation = 'landscape';
                        } else {
                            cropOrientation = 'portrait';
                        }

                        if (cropOrientation == 'portrait') {
                            // Image is portrait
                            const productRatio = productSize.width < productSize.height ? productSize.width / productSize.height : productSize.height / productSize.width;
                            log.verbose('Auto cropping portrait', imageRatio, productRatio, size * productRatio);
                            element.width(size * productRatio);
                            element.height(size);
                        } else if (cropOrientation == 'landscape') {
                            // Image is landscape
                            const productRatio = productSize.width > productSize.height ? productSize.width / productSize.height : productSize.height / productSize.width;
                            log.verbose('Auto cropping landscape', imageRatio, productRatio, size / productRatio);
                            element.width(size);
                            element.height(size / productRatio);
                        }
                    } else {
                        log.verbose('Cannot auto crop, falling back to square', image, product);
                        element.width(size);
                        element.height(size);
                    }
                };
                scope.$watch('[lineItem.product, lineItem.image.thumbnail.image, cart.fulfillment, cart.store]', resize);
                scope.$on('resize', resize);
                resize();
            },
        };
    },
]);
