app.factory('api', [
    '$rootScope',
    '$q',
    '$http',
    'config',
    'log',
    'exception',
    function (
        $rootScope,
        $q,
        $http,
        config,
        log,
        exception,
    ) {
        return new class Api {
            constructor() {
                if (config.api.printicular.environment == 'production') {
                    this.apiUrl = 'https://api.printicular.com/';
                } else if (config.api.printicular.environment == 'production-cache') {
                    this.apiUrl = 'https://capi.printicular.com/';
                } else if (config.api.printicular.environment == 'staging') {
                    this.apiUrl = 'https://stagingapi.printicular.com/';
                } else if (config.api.printicular.environment == 'staging-cache') {
                    this.apiUrl = 'https://stagingcapi.printicular.com/';
                } else {
                    this.apiUrl = config.api.printicular.url;
                }
                this.apiUrl += 'api/1.0/';
                log.verbose('API URL', config.api.printicular.environment, this.apiUrl);
            }

            request(method, url, data) {
                return $q((resolve, reject) => {
                    log.verbose('API request', method, this.apiUrl + url, data);
                    $http({
                        method: method,
                        url: this.apiUrl + url,
                        data: data,
                        headers: {
                            Authorization: 'Bearer ' + config.api.printicular.clientId,
                        },
                    }).then((response) => {
                        if (response.data && response.data.error) {
                            log.verbose('API error', method, this.apiUrl + url, data, response);
                            reject(response.data.error);
                            return;
                        }
                        log.verbose('API response', method, this.apiUrl + url, data, response);
                        resolve(response.data);
                    }, (response) => {
                        log.verbose('API failure', method, this.apiUrl + url, data, response);
                        if (response.data && response.data.error) {
                            reject(response.data.error);
                        } else {
                            reject(response);
                        }
                    });
                });
            }

            get(url, parameters) {
                if (parameters) {
                    url += '?' + $.param(parameters);
                }
                return this.request('get', url);
            }

            post(url, data) {
                return this.request('post', url, data);
            }

            put(url, data) {
                return this.request('put', url, data);
            }

            patch(url, data) {
                return this.request('patch', url, data);
            }

            param(name) {
                name = name.replace(/[[\]]/g, '\\$&');
                const url = window.location.href;
                const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
                const results = regex.exec(url);
                if (!results) {
                    return null;
                }
                if (!results[2]) {
                    return '';
                }
                return decodeURIComponent(results[2].replace(/\+/g, ' '));
            }

            gets3Resource() {
                log.verbose('Get S3 resource');
                return this.get('users/0/s3Resource?deviceToken=' + config.deviceToken, null).catch(error => {
                    const message = 'Could not get upload token, please try reloading the page.';

                    exception.report(message, error);

                    $rootScope.majorError = message;
                });
            }
        };
    },
]);
