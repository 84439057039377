app.factory('ProductTag', [
    'BaseModel',
    function (
        BaseModel,
    ) {
        return class ProductTag extends BaseModel {
            static isType(type) {
                return type == 'product_tags' || type == 'producttag';
            }
        };
    },
]);
