// app.factory('CartPromoProductImage', [
//     function (
//     ) {
//         return class CartPromoProductImage {
//             constructor(key, product) {
//                 this.key = key;
//                 this.product = product;
//             }

//             isReady() {
//                 return true;
//             }

//             isStarted() {
//                 return true;
//             }

//             isErrored() {
//                 return false;
//             }

//             getProgress() {
//                 return 1;
//             }

//             isCanvas() {
//                 return false;
//             }

//             isPromoProduct() {
//                 return true;
//             }

//             canEnlarge() {
//                 return true;
//             }

//             getImageId() {
//                 return null;
//             }

//             getProductImageUrl() {
//                 for (const productImage of this.product.productImages){
//                     return productImage.attributes.thumbnail;
//                 }
//             }
//         };
//     },
// ]);
