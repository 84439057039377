app.factory('thumbnailer', [
    '$q',
    'log',
    'Upload',
    function (
        $q,
        log,
        Upload,
    ) {
        const size = 800;
        return new class Thumbnailer {
            constructor() {
                this.thumbnails = [];
                this.thumbnailCache = {};
                this.thumbnailingCount = 0;
            }

            addFile(file) {
                const defer = $q.defer();
                const thumbnail = {
                    file: file,
                    load: defer.promise,
                    defer: defer,
                };
                this.thumbnails.push(thumbnail);
                this.startThumbnailing();
                return thumbnail;
            }

            startThumbnailing() {
                if (this.thumbnails.length > 0 && this.thumbnailingCount < 1) {
                    const thumbnail = this.thumbnails.shift();
                    this.thumbnail(thumbnail);
                }
            }

            thumbnail(thumbnail) {
                this.thumbnailingCount++;

                const file = thumbnail.file;
                const key = this.getFileKey(file);
                log.verbose('Thumbnailing', key, thumbnail);
                if (this.thumbnailCache[key]) {
                    log.verbose('Thumbnail cached', key);
                    thumbnail.url = this.thumbnailCache[key];
                    this.thumbnailingCount--;
                    this.startThumbnailing();
                    return;
                }

                if (!file.type.startsWith('image/')) {
                    log.verbose('Cant thumbnail, not image', file.type, key, thumbnail);
                    this.thumbnailingCount--;
                    this.startThumbnailing();
                    return;
                }

                let width = size;
                let height = size;
                Upload.resize(file, {
                    width,
                    height,
                }).then((file) => {
                    return Upload.applyExifRotation(file);
                }).then((file) => {
                    return this.readFile(file);
                }).then((url) => {
                    return this.loadImage(url);
                }).then((image) => {
                    thumbnail.image = image;
                    thumbnail.width = image.width;
                    thumbnail.height = image.height;
                    thumbnail.ready = true;
                }).catch((error) => {
                    thumbnail.error = true;
                    thumbnail.errorInfo = error;
                    log.error(error);
                }).finally(() => {
                    this.thumbnailingCount--;
                    this.startThumbnailing();
                    thumbnail.defer.resolve();
                });
            }

            readFile(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        resolve(e.target.result);
                    };
                    reader.onerror = (e) => {
                        log.error('Failed to read file', e);
                        reject();
                    };
                    reader.readAsDataURL(file);
                });
            }

            loadImage(imageUrl) {
                return new Promise((resolve, reject) => {
                    const image = new Image();
                    image.onload = () => {
                        resolve(image);
                    };
                    image.onerror = (e) => {
                        log.error('Failed to thumbnail image', e, e.message);
                        reject();
                    };
                    image.src = imageUrl;
                });
            }

            drawImageCover(context, image) {
                if (!image) {
                    return;
                }

                let x = 0;
                let y = 0;
                let w = context.canvas.width;
                let h = context.canvas.height;
                let offsetX = 0.5;
                let offsetY = 0.5;

                // keep bounds [0.0, 1.0]
                if (offsetX < 0) offsetX = 0;
                if (offsetY < 0) offsetY = 0;
                if (offsetX > 1) offsetX = 1;
                if (offsetY > 1) offsetY = 1;

                var iw = image.width,
                    ih = image.height,
                    r = Math.min(w / iw, h / ih),
                    nw = iw * r,   // new prop. width
                    nh = ih * r,   // new prop. height
                    cx, cy, cw, ch, ar = 1;

                // decide which gap to fill
                if (nw < w) ar = w / nw;
                if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh;  // updated
                nw *= ar;
                nh *= ar;

                // calc source rectangle
                cw = iw / (nw / w);
                ch = ih / (nh / h);

                cx = (iw - cw) * offsetX;
                cy = (ih - ch) * offsetY;

                // make sure source rectangle is valid
                if (cx < 0) cx = 0;
                if (cy < 0) cy = 0;
                if (cw > iw) cw = iw;
                if (ch > ih) ch = ih;

                // fill image in dest. rectangle
                context.drawImage(image, cx, cy, cw, ch, x, y, w, h);
            }

            getFileKey(file) {
                return [file.name, file.size, file.lastModified].join(':');
            }
        };
    },
]);
