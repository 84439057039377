app.directive('pModalCrop', [
    '$rootScope',
    'CropRectPos',
    function (
        $rootScope,
        CropRectPos,
    ) {
        return {
            restrict: 'A',
            scope: {
            },
            template: `
                <div class="modal-dialog p-modal-dialog-fullscreen" role="document">
                    <div class="modal-content">
                        <div class="modal-header text-center">
                            <button type="button" class="close" ng-click="closeModal()"><span aria-hidden="true">×</span></button>
                            <h4 class="modal-title m-0">Crop Photo</h4>
                        </div>
                        <div class="modal-body">
                            <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    class="p-crop-svg"
                                    width="100%"
                                    height="100%"
                                    viewBox="0 0 100 100">
                                <defs class="p-crop-ui">
                                    <mask id="crop-mask">
                                        <rect class="p-crop-overlay" fill="white" />
                                        <rect class="p-crop-rect" fill="black" />
                                    </mask>
                                </defs>
                                <image
                                    x="0"
                                    y="0"
                                    width="100"
                                    height="100"
                                    class="p-crop-image"
                                    preserveAspectRatio="none"
                                    xlink:href="${window.TRANSPARENT_PIXEL}"
                                />
                                <rect class="p-crop-overlay p-crop-overlay-shade p-crop-ui" mask="url(#crop-mask)" />
                                <rect class="p-crop-rect-debug p-crop-ui" />
                                <rect class="p-crop-rect p-crop-rect-outline p-crop-ui" />
                                <path class="p-crop-rect-handle p-crop-rect-handle-tl p-crop-ui" d="M2 0 L25 0 L25 5 L5 5 L5 25 L0 25 L0 2 Z" />
                                <path class="p-crop-rect-handle p-crop-rect-handle-tr p-crop-ui" d="M2 0 L25 0 L25 5 L5 5 L5 25 L0 25 L0 2 Z" />
                                <path class="p-crop-rect-handle p-crop-rect-handle-bl p-crop-ui" d="M2 0 L25 0 L25 5 L5 5 L5 25 L0 25 L0 2 Z" />
                                <path class="p-crop-rect-handle p-crop-rect-handle-br p-crop-ui" d="M2 0 L25 0 L25 5 L5 5 L5 25 L0 25 L0 2 Z" />
                                <path class="p-crop-grid p-crop-ui" d="M33 0 L33 100 M66 0 L66 100 M0 33 L100 33 M0 66 L 100 66" />
                            </svg>
                        </div>
                        <div class="modal-footer d-block text-center" ng-if="lineItem">
                            <div class="p-modal-footer-bar mb-3">
                                <div>
                                    <div class="dropup" style="display: inline-block" ng-if="lineItem.getAvailableProducts().length">
                                        <button class="btn btn-default btn-block dropdown-toggle p-prints-dropdown p-prints-dropdown-crop" type="button" data-toggle="dropdown">
                                            {{ lineItem.product.formatName() }}
                                            <span class="caret"></span>
                                        </button>
                                        <div class="dropdown-menu">
                                            <a
                                                href=""
                                                class="dropdown-item"
                                                ng-repeat="product in lineItem.getAvailableProducts() | reverse" class="dropdown-item"
                                                ng-click="$event.preventDefault(); lineItem.setProduct(product); updateImage()">
                                                {{ product.formatName() }}
                                            </a>
                                        </div>
                                    </div>
                                    <button type="button" class="btn btn-default p-rotate-crop" ng-click="rotate()">
                                        <span>{{ lang.jsRotate }}</span>
                                    </button>
                                    <button class="btn btn-default"
                                            type="button"
                                            ng-show="cropRectPos.orientation === 'landscape'"
                                            ng-click="togglePortrait()">
                                        <span>Portrait</span>
                                    </button>
                                    <button class="btn btn-default"
                                            type="button"
                                            ng-show="cropRectPos.orientation !== 'landscape'"
                                            ng-click="toggleLandscape()">
                                        <span>Landscape</span>
                                    </button>
                                </div>
                                <button type="button" class="btn btn-primary p-apply-crop" ng-click="apply()">{{ lang.jsApply }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            `,
            link: function (scope, element) {
                scope.lang = $rootScope.lang;
                const svg = element.find('svg');
                const image = svg.find('image');

                let view = {
                    x: 0,
                    y: 0,
                    width: 0,
                    height: 0,
                };
                let resizing = false;
                let moving = false;
                let movingOffset = null;
                let cropRectPos = null;

                const cropRect = element.find('.p-crop-rect');
                const cropRectDebug = element.find('.p-crop-rect-debug');
                const cropRectGrid = element.find('.p-crop-grid');
                const cropOverlay = element.find('.p-crop-overlay');
                const cropRectTl = element.find('.p-crop-rect-handle-tl');
                const cropRectTr = element.find('.p-crop-rect-handle-tr');
                const cropRectBl = element.find('.p-crop-rect-handle-bl');
                const cropRectBr = element.find('.p-crop-rect-handle-br');
                const updateCropRect = () => {
                    cropRectDebug.attr({
                        x: cropRectPos.x,
                        y: cropRectPos.y,
                        width: cropRectPos.width,
                        height: cropRectPos.height,
                    }).show();
                    const aspect = cropRectPos.getAspectRect();
                    cropRect.attr({
                        x: aspect.x,
                        y: aspect.y,
                        width: aspect.width,
                        height: aspect.height,
                    });
                    cropRectGrid.css({
                        transform: `translate(${aspect.x}px, ${aspect.y}px) scale(${aspect.width / 100}, ${aspect.height / 100})`,
                    });
                    const handleSize = 25;
                    const padding = 7;
                    cropRectTl.css({
                        transform: `translate(${aspect.x - padding}px, ${aspect.y - padding}px) scaleX(1)`,
                    });
                    cropRectTr.css({
                        transform: `translate(${aspect.x + aspect.width - handleSize + padding}px, ${aspect.y - padding}px) scaleX(-1)`,
                    });
                    cropRectBl.css({
                        transform: `translate(${aspect.x - padding}px, ${aspect.y + aspect.height - handleSize + padding}px) scaleY(-1)`,
                    });
                    cropRectBr.css({
                        transform: `translate(${aspect.x + aspect.width - handleSize + padding}px, ${aspect.y + aspect.height - handleSize + padding}px) scaleX(-1) scaleY(-1)`,
                    });
                };

                const transformEventPos = (e) => {
                    const point = svg[0].createSVGPoint();
                    if (e.originalEvent.touches) {
                        point.x = e.originalEvent.touches[0].clientX;
                        point.y = e.originalEvent.touches[0].clientY;
                    } else {
                        point.x = e.clientX;
                        point.y = e.clientY;
                    }
                    return point.matrixTransform(svg[0].getScreenCTM().inverse());
                };

                const pointDistance = (x1, y1, x2, y2) => {
                    return Math.sqrt((x2 -= x1) * x2 + (y2 -= y1) * y2);
                };

                const isCloseTo = (x1, y1, x2, y2, smallestSide) => {
                    const distance = pointDistance(x1, y1, x2, y2);
                    const percent = distance / smallestSide;
                    return percent < 0.25;
                };

                const getDraggableRegion = (point) => {
                    const aspect = cropRectPos.getAspectRect();
                    const smallestSide = aspect.width > aspect.height ? aspect.height : aspect.width;

                    if (isCloseTo(point.x, point.y, aspect.x, aspect.y, smallestSide)) {
                        return 'top-left';
                    }
                    if (isCloseTo(point.x, point.y, aspect.x + aspect.width, aspect.y, smallestSide)) {
                        return 'top-right';
                    }
                    if (isCloseTo(point.x, point.y, aspect.x, aspect.y + aspect.height, smallestSide)) {
                        return 'bottom-left';
                    }
                    if (isCloseTo(point.x, point.y, aspect.x + aspect.width, aspect.y + aspect.height, smallestSide)) {
                        return 'bottom-right';
                    }
                    if (point.x >= aspect.x &&
                        point.y >= aspect.y &&
                        point.x <= aspect.x + aspect.width &&
                        point.y <= aspect.y + aspect.height) {
                        return 'center';
                    }

                    return null;
                };

                svg.on('mousedown touchstart', (e) => {
                    if (e.type != 'touchstart' && e.which != 1) {
                        return;
                    }
                    const point = transformEventPos(e);
                    const draggableRegion = getDraggableRegion(point);

                    switch (draggableRegion) {
                        case 'center':
                            moving = true;
                            movingOffset = {
                                x: (cropRectPos.width) / 2 - (point.x - cropRectPos.x),
                                y: (cropRectPos.height) / 2 - (point.y - cropRectPos.y),
                            };
                            break;
                        case 'top-left':
                            resizing = true;
                            cropRectPos.fixedX = cropRectPos.x + cropRectPos.width;
                            cropRectPos.fixedY = cropRectPos.y + cropRectPos.height;
                            cropRectPos.floatX = point.x;
                            cropRectPos.floatY = point.y;
                            updateCropRect();
                            break;
                        case 'top-right':
                            resizing = true;
                            cropRectPos.fixedX = cropRectPos.x;
                            cropRectPos.fixedY = cropRectPos.y + cropRectPos.height;
                            cropRectPos.floatX = point.x;
                            cropRectPos.floatY = point.y;
                            updateCropRect();
                            break;
                        case 'bottom-left':
                            resizing = true;
                            cropRectPos.fixedX = cropRectPos.x + cropRectPos.width;
                            cropRectPos.fixedY = cropRectPos.y;
                            cropRectPos.floatX = point.x;
                            cropRectPos.floatY = point.y;
                            updateCropRect();
                            break;
                        case 'bottom-right':
                            resizing = true;
                            cropRectPos.fixedX = cropRectPos.x;
                            cropRectPos.fixedY = cropRectPos.y;
                            cropRectPos.floatX = point.x;
                            cropRectPos.floatY = point.y;
                            updateCropRect();
                            break;
                        default:
                            if (point.x > view.x && point.y > view.y && point.x < view.x + view.width && point.y < view.y + view.height) {
                                resizing = true;
                                cropRectPos.fixedX = point.x;
                                cropRectPos.fixedY = point.y;
                                cropRectPos.floatX = point.x + 1;
                                cropRectPos.floatY = point.y + 1;
                                updateCropRect();
                            }
                            break;
                    }
                });

                const endDrag = () => {
                    if (resizing) {
                        const aspect = cropRectPos.getAspectRect();
                        cropRectPos.fixedX = aspect.x;
                        cropRectPos.fixedY = aspect.y;
                        cropRectPos.floatX = aspect.x + aspect.width;
                        cropRectPos.floatY = aspect.y + aspect.height;
                        updateCropRect();
                    }
                    moving = false;
                    resizing = false;
                    cropRectDebug.hide();
                };

                $('.p-modal-crop').on('mousemove touchmove', (e) => {
                    if (e.buttons !== undefined && e.buttons !== 1 && e.type != 'touchmove') {
                        endDrag();
                    }
                    const point = transformEventPos(e);
                    const draggableRegion = getDraggableRegion(point);
                    switch (draggableRegion) {
                        case 'center':
                            svg.css('cursor', 'move');
                            break;
                        case 'top-left':
                            svg.css('cursor', 'nwse-resize');
                            break;
                        case 'top-right':
                            svg.css('cursor', 'nesw-resize');
                            break;
                        case 'bottom-left':
                            svg.css('cursor', 'nesw-resize');
                            break;
                        case 'bottom-right':
                            svg.css('cursor', 'nwse-resize');
                            break;
                        default:
                            if ($(e.target).closest('.p-crop-image, .p-crop-ui').length) {
                                svg.css('cursor', 'crosshair');
                            } else {
                                svg.css('cursor', '');
                            }
                            break;
                    }

                    if (moving) {
                        const pointOffset = {
                            x: point.x + movingOffset.x,
                            y: point.y + movingOffset.y,
                        };
                        const aspect = cropRectPos.getAspectRect();
                        const offsetX = aspect.width / 2;
                        const offsetY = aspect.height / 2;
                        cropRectPos.fixedX = pointOffset.x - offsetX;
                        cropRectPos.fixedY = pointOffset.y - offsetY;
                        cropRectPos.floatX = pointOffset.x + offsetX;
                        cropRectPos.floatY = pointOffset.y + offsetY;
                        if (cropRectPos.fixedX < view.x) {
                            cropRectPos.floatX = view.x + aspect.width;
                            cropRectPos.fixedX = view.x;
                        }
                        if (cropRectPos.fixedY < view.y) {
                            cropRectPos.floatY = view.y + aspect.height;
                            cropRectPos.fixedY = view.y;
                        }
                        if (cropRectPos.floatX > view.x + view.width) {
                            cropRectPos.fixedX = view.x + view.width - aspect.width;
                            cropRectPos.floatX = view.x + view.width;
                        }
                        if (cropRectPos.floatY > view.y + view.height) {
                            cropRectPos.fixedY = view.y + view.height - aspect.height;
                            cropRectPos.floatY = view.y + view.height;
                        }
                        updateCropRect();
                    } else if (resizing) {
                        if (point.x < view.x) {
                            point.x = view.x;
                        }
                        if (point.y < view.y) {
                            point.y = view.y;
                        }
                        if (point.x > view.x + view.width) {
                            point.x = view.x + view.width;
                        }
                        if (point.y > view.y + view.height) {
                            point.y = view.y + view.height;
                        }
                        cropRectPos.floatX = point.x;
                        cropRectPos.floatY = point.y;
                        updateCropRect();
                    }
                });

                $(document).on('mouseup touchend', endDrag);

                scope.rotate = () => {
                    cropRectPos.rotate();
                    scope.updateImage(true);
                };

                scope.updateImage = (resetPosition = false) => {
                    if (cropRectPos.rotation == 1 || cropRectPos.rotation == 3) {
                        view.width = scope.lineItem.image.thumbnail.height;
                        view.height = scope.lineItem.image.thumbnail.width;
                    } else {
                        view.width = scope.lineItem.image.thumbnail.width;
                        view.height = scope.lineItem.image.thumbnail.height;
                    }
                    view.x = -view.width / 2;
                    view.y = -view.height / 2;
                    svg.attr({
                        viewBox: `${view.x} ${view.y} ${view.width} ${view.height}`,
                        width: view.width,
                        height: view.height,
                    });
                    cropOverlay.attr({
                        x: view.x,
                        y: view.y,
                        width: view.width,
                        height: view.height,
                    });
                    image.attr({
                        'xlink:href': scope.lineItem.image.thumbnail.image.src,
                        width: scope.lineItem.image.thumbnail.width,
                        height: scope.lineItem.image.thumbnail.height,
                    }).css({
                        transformOrigin: `${scope.lineItem.image.thumbnail.width / 2}px ${scope.lineItem.image.thumbnail.height / 2}px`,
                        transform: `translate(-${scope.lineItem.image.thumbnail.width / 2}px, -${scope.lineItem.image.thumbnail.height / 2}px) rotate(${cropRectPos.rotation * 90}deg)`,
                    });

                    cropRectPos.productSize = scope.lineItem.product.getProductSize();
                    let cropWidth = view.width;
                    let cropHeight = view.height;
                    const ratio = view.width / view.height;
                    const productRatio = cropRectPos.productWidth / cropRectPos.productHeight;
                    if (ratio < productRatio) {
                        cropHeight = cropRectPos.productHeight / cropRectPos.productWidth * view.width;
                    } else {
                        cropWidth = cropRectPos.productWidth / cropRectPos.productHeight * view.height;
                    }
                    if (resetPosition || (!cropRectPos.fixedX && !cropRectPos.fixedY && !cropRectPos.floatX && !cropRectPos.floatY)) {
                        cropRectPos.fixedX = -cropWidth / 2;
                        cropRectPos.fixedY = -cropHeight / 2;
                        cropRectPos.floatX = cropRectPos.fixedX + cropWidth;
                        cropRectPos.floatY = cropRectPos.fixedY + cropHeight;
                    }
                    updateCropRect();
                };

                scope.apply = () => {
                    const aspect = cropRectPos.getAspectRect();
                    let processedSvg = svg.clone();
                    processedSvg.removeAttr('width');
                    processedSvg.removeAttr('height');
                    processedSvg.attr('viewBox', `${aspect.x} ${aspect.y} ${aspect.width} ${aspect.height}`);
                    processedSvg.find('.p-crop-ui').remove();
                    processedSvg = $('<div>').append(processedSvg).html();
                    processedSvg = processedSvg.replace(/\s+/g, ' ');
                    scope.lineItem.svg = processedSvg;
                    scope.lineItem.previewSvg = processedSvg;
                    scope.lineItem.cropRectPos = cropRectPos;
                    scope.closeModal();
                };

                scope.closeModal = () => {
                    $rootScope.modalCrop = null;
                    $rootScope.modalFrameFlow = null;
                };

                scope.togglePortrait = () => {
                    cropRectPos.orientation = 'portrait';
                    scope.updateImage(true);
                };

                scope.toggleLandscape = () => {
                    cropRectPos.orientation = 'landscape';
                    scope.updateImage(true);
                };

                $rootScope.$watch('modalCrop', (lineItem) => {
                    scope.lineItem = lineItem;
                    if (lineItem) {
                        cropRectPos = scope.lineItem.cropRectPos || new CropRectPos();
                        scope.cropRectPos = cropRectPos;
                        if (!cropRectPos.orientation) {
                            if (scope.lineItem.image.thumbnail.width > scope.lineItem.image.thumbnail.height) {
                                cropRectPos.orientation = 'landscape';
                            } else {
                                cropRectPos.orientation = 'portrait';
                            }
                        }
                        scope.updateImage();
                        element.modal('show');
                    } else {
                        element.modal('hide');
                    }
                });
            },
        };
    },
]);
