const breakpoints = {
    xs: [0, 575],
    sm: [576, 767],
    md: [768, 991],
    lg: [992],
};

function validateSize (size) {
    if (!breakpoints[size]) {
        throw new Error('Invaild breakpoint given. Valid sizes are ' + Object.keys(breakpoints).join(', '));
    }
}

const browser = {
    isIe11: !!window.MSInputMethodContext && !!document['documentMode'],
    isSafari: /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
    isAndroid: (navigator.userAgent.toLowerCase().indexOf('android') > -1),
    isIos: /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream,
    isSupported () {
        return !this.isUnsupported();
    },
    isUnsupported () {
        return (
            this.isIe11
        );
    },
    mediaBreakpointUp (size) {
        validateSize(size);
        return window.innerWidth >= breakpoints[size][0];
    },
    mediaBreakpointDown (size) {
        validateSize(size);
        const px = breakpoints[size][1] || breakpoints[size][0];
        return window.innerWidth < px;
    },

    breakpoint () {
        for (let key of Object.keys(breakpoints).reverse()) {
            if (this.mediaBreakpointUp(key)) {
                return key;
            }
        }

        return 'xl';
    },
};


app.factory('browser', [function () {
    return browser;
}]);
