app.filter('distance', [
    '$rootScope',
    function (
        $rootScope,
    ) {
        return function (store) {
            let territory = $rootScope.territories ? $rootScope.territories.find(territory => territory.id == store.attributes.territory_id) : null;
            let amount = store.meta.distance;
            if (amount === null) {
                return '';
            }
            if (territory && territory.attributes.country_code == 'US') {
                switch (Math.round(amount)) {
                    case 0:
                        return 'less than 1 mile away';
                    case 1:
                        return '1 mile away';
                }
                return Math.round(amount) + ' miles away';
            }
            amount = amount * 1.609344;
            switch (Math.round(amount)) {
                case 0:
                    return 'less than 1 km away';
                case 1:
                    return '1 km away';
            }
            return Math.round(amount) + ' km\'s away';
        };
    },
]);
