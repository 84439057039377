app.directive('pCartItemPreview', [
    'cropHelpers',
    'frameHelpers',
    'cart',
    function (
        cropHelpers,
        frameHelpers,
        cart,
    ) {
        return {
            restrict: 'A',
            scope: {
                lineItem: '=',
            },
            link: function (scope, element) {
                scope.cart = cart;

                const maxHeight = element.height();

                element.html(getPreviewHtml());
                resize();

                scope.$on('resize', () => {
                    resize();
                    scope.$digest();
                });

                scope.$watch('[lineItem.previewSvg, lineItem.image.thumbnail.image.src, lineItem.product, cart.fulfillment, cart.store]', () => {
                    setTimeout(() => {
                        element.html(getPreviewHtml());
                        resize();
                    }, 1);
                });

                function resize () {
                    const el = element.children().first();

                    if (el.height() > maxHeight) {
                        el.height(maxHeight);
                    }
                }

                function getPreviewHtml () {
                    if (scope.lineItem) {
                        // Designer prints
                        if (scope.lineItem.previewImage) {
                            return scope.lineItem.previewImage;
                        }

                        // Framed Products
                        if (scope.lineItem.product &&
                            scope.lineItem.product.metaData.frame &&
                            scope.lineItem.image &&
                            scope.lineItem.image.getSrc()) {
                            return frameHelpers.render(scope.lineItem);
                        }

                        // Cards, Calendars, Canvas, etc
                        if (scope.lineItem.previewSvg) {
                            return scope.lineItem.previewSvg;
                        }

                        // Print Products
                        if (scope.lineItem.image) {
                            // Manually add cart image data
                            if (!scope.lineItem.image.file && scope.lineItem.image.data) {
                                return scope.lineItem.image.data;
                            }
                            // Uploaded photo
                            if (scope.lineItem.image.getSrc()) {
                                return cropHelpers.render(scope.lineItem);
                            }
                        }
                    }

                    return '<div class="spinner"></div>';
                }
            },
        };
    }]);
