app.controller('PrintController', [
    '$interpolate',
    '$rootScope',
    '$sce',
    '$scope',
    '$timeout',
    'api',
    'cart',
    'CartLineItem',
    'config',
    'gtm',
    'log',
    'query',
    function (
        $interpolate,
        $rootScope,
        $sce,
        $scope,
        $timeout,
        api,
        cart,
        CartLineItem,
        config,
        gtm,
        log,
        query,
    ) {
        window.PRINT = $scope;

        if (query.thanks) {
            cart.orderNumber = 1;
        }

        api.get('territories').then((response) => {
            $rootScope.territories = response.data.filter((territory) => {
                if (config.locale.defaultDeliveryCountry && config.locale.defaultDeliveryCountry.code == territory.attributes.country_code) {
                    cart.addressCountry = territory;
                }
                if (!config.locale.deliveryCountries || !config.locale.deliveryCountries.length) {
                    return true;
                }
                for (const deliveryCountry of config.locale.deliveryCountries) {
                    if (deliveryCountry.code == territory.attributes.country_code) {
                        return true;
                    }
                }
                return false;
            });
            const defaultCountry = $rootScope.territories.find(territory => territory.id == cart.addressTerritoryId);
            if (defaultCountry) {
                cart.addressCountry = defaultCountry;
            }
        });


        $scope.files = [];
        $scope.displayUploadZone = false;

        $scope.$watch('files', (files) => {
            log.verbose('Added files', files.length);

            $rootScope.uploadErrors = [];
            for (let i = 0; i < files.length; i++) {
                const image = cart.addImage(files[i]);

                if (image) {
                    cart.addLineItem(new CartLineItem({ cart, image }));
                }
            }

            if (files.length) {
                gtm.push({
                    event: 'Add files',
                    count: files.length,
                });
            }
        });

        $scope.duplicateLineItem = (lineItem, index) => {
            const duplicate = new CartLineItem({ cart, image: lineItem.image, product: lineItem.product });

            if (lineItem.settings) {
                duplicate.settings = $.extend({}, lineItem.settings);
            }

            cart.lineItems.splice(index, 0, duplicate);
            cart.updateSummary();
        };

        const checkWaitingUpload = () => {
            if (cart.summary.totalUnready > 0) {
                $timeout(checkWaitingUpload, 300);
                return;
            }
            sendOrder();
        };

        const gaProcessOrder = (response) => {
            let gaProducts = {};
            let lineItems = response.included.filter(included => included.type == 'line_items');
            for (let i = 0; i < lineItems.length; i++) {
                const lineItem = lineItems[i];
                const cartLineItem = cart.lineItems[i] || null;
                const lineItemKey = lineItem.attributes.product_id + ':' + (cartLineItem ? cartLineItem.variant : 'none');
                if (!gaProducts[lineItemKey]) {
                    let product = null;
                    if (cart.fulfillment == 'delivery') {
                        product = cart.deliveryProducts.find(product => product.id == lineItem.attributes.product_id);
                    } else {
                        product = cart.store.products.find(product => product.id == lineItem.attributes.product_id);
                    }
                    gaProducts[lineItemKey] = {
                        id: lineItem.attributes.product_id.toString(),
                        name: product ? product.attributes.name : null,
                        category: product ? product.attributes.category_name : null,
                        variant: cartLineItem ? cartLineItem.variant : null,
                        price: lineItem.attributes.unit_price,
                        quantity: 0,
                        brand: cart.store.attributes.retailer_id,
                    };
                }
                gaProducts[lineItemKey].quantity += lineItem.attributes.quantity;
            }
            const transactionData = {
                event: 'purchase',
                ecommerce: {
                    currencyCode: cart.getCurrency(),
                    purchase: {
                        actionField: {
                            id: response.data.id.toString(),
                            revenue: response.data.attributes.total,
                            affiliation: cart.store ? cart.store.attributes.name : 'Delivery',
                            tax: response.data.attributes.tax_total,
                            shipping: response.data.attributes.freight_total,
                            coupon: cart.couponCode,
                        },
                        products: Object.values(gaProducts),
                    },
                },
            };
            log.debug('Ecommerce data', transactionData);

            gtm.push(transactionData);
            gtm.push({
                event: 'Submit order',
                fulfillment: cart.store ? cart.store.attributes.name : 'Delivery',
                orderId: response.data.id,
                orderValue: Math.round(response.data.attributes.total * 100),
            });
        };

        const paymentComplete = (token) => {
            const order = cart.getOrderJson(false, token);
            api.post('users/0/orders?include=store,lineItems', order).then((response) => {
                window.onbeforeunload = null;
                const orderNumber = response.data.id;
                $scope.placingOrder = false;
                cart.orderNumber = orderNumber;
                $(window).scrollTop(0);
                gaProcessOrder(response);
            }).catch((response) => {
                log.debug('Error placing order', response, log.mapErrors(response));
                $scope.placingOrder = false;
                $scope.errors = log.mapErrors(response);
                gtm.push({
                    event: 'Submit order failed',
                    errorMessage: $scope.errors.join('\n'),
                    fulfillment: cart.store ? cart.store.attributes.name : 'Delivery',
                    orderValue: Math.round(cart.summary.totalPrice * 100),
                });
            });
        };

        const sendOrder = () => {
            $scope.waitingUpload = false;
            $scope.errors = cart.validate();
            if ($scope.errors.length > 0) {
                gtm.push({
                    event: 'Submit order failed',
                    errorMessage: $scope.errors.join('\n'),
                    fulfillment: cart.store ? cart.store.attributes.name : 'Delivery',
                    orderValue: Math.round(cart.summary.totalPrice * 100),
                });
                return;
            }
            $scope.placingOrder = true;

            if (cart.fulfillment == 'pickup') {
                paymentComplete();
                return;
            }

            $rootScope.stripe.createToken($rootScope.card).then((result) => {
                if (result.error) {
                    $scope.errors = [result.error.message];
                    $scope.placingOrder = false;
                    gtm.push({
                        event: 'Payment failed',
                        errorMessage: result.error.message,
                        fulfillment: cart.store ? cart.store.attributes.name : 'Delivery',
                        orderValue: Math.round(cart.summary.totalPrice * 100),
                    });
                } else {
                    paymentComplete(result.token.id);
                }
                $scope.$apply();
            });
        };

        $scope.placeOrder = ($event) => {
            $event.preventDefault();
            if (cart.summary.totalUnready > 0) {
                $scope.waitingUpload = true;
                $timeout(checkWaitingUpload, 300);
                return;
            }
            sendOrder();
        };

        $scope.content = {};
        $scope.$watch('cart.orderNumber', () => {
            for (const key in config.content) {
                $scope.content[key] = $sce.trustAsHtml($interpolate(config.content[key])($scope));
            }
        });

        $scope.reload = () => {
            location.reload();
        };

        $scope.coupon = {
            code: null,
        };
        $scope.applyCoupon = () => {
            log.debug('Applying coupon', $scope.coupon.code);
            $scope.couponErrors = [];

            if (!$scope.coupon.code) {
                $scope.couponErrors = ['Please enter a coupon code.'];
                return;
            }
            cart.couponCode = $scope.coupon.code;
            cart.updateSummary().then(() => {
                if (!cart.summary.couponCode) {
                    cart.couponCode = null;
                    $scope.couponErrors = cart.dryRunErrors;
                    cart.updateSummary();
                    gtm.push({
                        event: 'Invalid coupon',
                        fulfillment: cart.store ? cart.store.attributes.name : 'Delivery',
                        orderValue: Math.round(cart.summary.totalPrice * 100),
                        couponCode: cart.couponCode,
                    });
                } else {
                    gtm.push({
                        event: 'Apply coupon',
                        totalDiscount: Math.round(cart.summary.totalDiscount + cart.summary.shippingDiscount * 100),
                        fulfillment: cart.store ? cart.store.attributes.name : 'Delivery',
                        orderValue: Math.round(cart.summary.totalPrice * 100),
                        couponCode: cart.couponCode,
                    });
                }
            });
        };

        $scope.removeCoupon = () => {
            cart.couponCode = null;
            cart.updateSummary();
        };

        $scope.updateCustomer = () => {
            if (cart.fulfillment == 'delivery') {
                cart.updateSummary();
            }
        };
    },
]);
