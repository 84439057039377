app.factory('Unique', [
    function (
    ) {
        const cache = {};
        return {
            random: (prefix = 'uid') => {
                let uid;
                do {
                    const number = Math.random().toString().replace(/0\./, '');
                    uid = prefix + '-' + number;
                } while (cache[uid]);
                cache[uid] = true;
                return uid;
            },

            inc: (prefix = 'uid') => {
                let nextId = 0;
                do {
                    nextId++;
                } while (cache[prefix + '-' + nextId]);
                cache[prefix + '-' + nextId] = true;
                return prefix + '-' + nextId;
            },
        };
    },
]);
