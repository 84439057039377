app.factory('frameHelpers', [
    'Unique',
    function (
        Unique,
    ) {
        function createFrameSvg(lineItem, frame) {
            const uid = Unique.random();
            const src = lineItem.image.coverImageThumbnail || lineItem.image.getSrc();

            return `
                <svg
                    viewBox="0 0 ${frame.width} ${frame.height}"
                    width="100%"
                    height="100%"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink">
                    <defs>
                        <clipPath id="${uid}">
                            <rect
                                x="${frame.window.x}"
                                y="${frame.window.y}"
                                width="${frame.window.width}"
                                height="${frame.window.height}">
                            </rect>
                        </clipPath>
                    </defs>
                    <g>
                        <image
                            data-element="frame"
                            x="0"
                            y="0"
                            height="${frame.height}"
                            width="${frame.width}"
                            xlink:href="${frame.src}">
                        </image>
                    </g>
                    <g clip-path="url(#${uid})">
                        <image
                            data-element="photo"
                            x="0"
                            y="0"
                            width="${lineItem.settings.width}"
                            height="${lineItem.settings.height}"
                            xlink:href="${src}"
                            style="
                                transform-origin: center center;
                                transform-box: fill-box;
                                transform: translate(${lineItem.settings.x}px, ${lineItem.settings.y}px) scale(${lineItem.settings.scale}) rotate(${lineItem.settings.rotate}deg);
                            ">
                        </image>
                    </g>
                </svg>
            `;
        }

        return {
            getFrames (lineItem) {
                return lineItem.product.productImages
                    .filter(img => !!img.metaData.frame)
                    .map(img => {
                        img.metaData.frame.isPortrait = img.metaData.frame.height > img.metaData.frame.width;
                        img.metaData.frame.orientation = img.metaData.frame.isPortrait ? 'portrait' : 'landscape';
                        img.metaData.frame.src = img.attributes.thumbnail;
                        return img.metaData.frame;
                    });
            },

            getCurrentFrame(lineItem, preferOrientation) {
                if (! lineItem.settings) {
                    lineItem.settings = {};
                }

                const frames = this.getFrames(lineItem);
                const orientation = preferOrientation || lineItem.settings.orientation || lineItem.image.orientation || lineItem.image.getOrientation();

                return frames.find(frame => frame.orientation === orientation) || frames[0];
            },

            extractPhotoSvg(svg, frame) {
                const clone = $(svg).clone();
                clone.attr({ 'viewBox': `${frame.window.x} ${frame.window.y} ${frame.window.width} ${frame.window.height}` });
                clone.find('[data-element=frame]').parent().remove();

                return $('<div>').append(clone).html();
            },

            getPhotoDefaults(frame, image) {
                const windowCenterX = frame.window.x + (frame.window.width / 2);
                const windowCenterY = frame.window.y + (frame.window.height / 2);
                const windowWidth = frame.window.width;
                const windowHeight = frame.window.height;
                const windowRatio = windowWidth / windowHeight;

                let width = 0;
                let height = 0;

                const imageWidth = image.width || image.getWidth();
                const imageHeight = image.height || image.getHeight();
                const imageRatio = imageWidth / imageHeight;

                if (windowRatio > imageRatio) {
                    width = windowWidth;
                    height = (windowWidth / imageWidth) * imageHeight;
                } else {
                    width = (windowHeight / imageHeight) * imageWidth;
                    height = windowHeight;
                }

                return {
                    width,
                    height,
                    scale: 1,
                    rotate: 0,
                    x: windowCenterX - (width / 2),
                    y: windowCenterY - (height / 2),
                };
            },

            render (lineItem) {
                if (! lineItem.settings) {
                    lineItem.settings = {};
                }

                const frame = this.getCurrentFrame(lineItem);

                if (! lineItem.settings.x) {
                    const defaults = this.getPhotoDefaults(frame, lineItem.image);

                    lineItem.settings.x = defaults.x;
                    lineItem.settings.y = defaults.y;
                    lineItem.settings.width = defaults.width;
                    lineItem.settings.height = defaults.height;
                    lineItem.settings.scale = defaults.scale;
                    lineItem.settings.rotate = defaults.rotate;

                    const svg = createFrameSvg(lineItem, frame);
                    lineItem.svg = this.extractPhotoSvg(svg, frame);
                    // FIX: make sure orientation is correct when sent to server
                    lineItem.cropRectPos = {
                        orientation: frame.isPortrait ? 'portrait' : 'landscape',
                    };
                    return svg;
                }

                return createFrameSvg(lineItem, frame);
            },
        };
    },
]);
