window.onerror = function(error) {
    dataLayer.push({
        event:'Unhandled error',
        message: error,
    });
};

window.addEventListener('unhandledrejection', function(error) {
    dataLayer.push({
        event: 'Unhandled error',
        message: error,
    });
});

