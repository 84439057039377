app.directive('pPagination', [
    'log',
    function (
        log,
    ) {
        return {
            restrict: 'A',
            scope: {
                page: '=pPagination',
                totalItems: '=pPaginationTotalItems',
                itemsPerPage: '=pPaginationItemsPerPage',
                scrollTo: '=pPaginationScrollTo',
                change: '&pPaginationChange',
            },
            template: `
                <ul class="pagination justify-content-center" ng-show="pages.length > 1">
                    <li class="page-item" ng-class="{'disabled': page == 1}" ng-click="prevPage()">
                        <span class="page-link">Previous</span>
                    </li>
                    <li class="page-item" ng-class="{'active': p == page}" ng-repeat="p in pages" ng-click="gotoPage(p)">
                        <span class="page-link">{{ p }}</span>
                    </li>
                    <li class="page-item" ng-class="{'disabled': page == lastPage}" ng-click="nextPage()">
                        <span class="page-link">Next</span>
                    </li>
                </ul>
            `,
            link: function (scope) {
                scope.page = scope.page || 1;

                const updatePages = () => {
                    log.debug('Updating pagination', scope.totalItems, scope.itemsPerPage, scope.page, scope.scrollTo);
                    scope.pages = [];
                    let startPage = Math.max(1, scope.page - 2);
                    const endPage = Math.min(startPage + 4, scope.lastPage);
                    if (endPage >= scope.lastPage - 1) {
                        startPage = Math.max(1, endPage - 4);
                    }
                    for (let i = startPage; i <= endPage; i++) {
                        scope.pages.push(i);
                    }
                    if (scope.scrollTo) {
                        const offsetTop = $(scope.scrollTo).offset().top - 30;
                        if ($('html').scrollTop() > offsetTop) {
                            $('html').scrollTop(offsetTop);
                        }
                    }
                };

                scope.$watch('page', () => {
                    updatePages();
                });

                scope.$watch('totalItems', () => {
                    scope.lastPage = Math.ceil(scope.totalItems / scope.itemsPerPage);
                    updatePages();
                });

                scope.gotoPage = (pageNumber) => {
                    scope.page = pageNumber;
                    updatePages();
                };

                scope.nextPage = () => {
                    if (scope.page == scope.lastPage) return;
                    scope.page += 1;
                    updatePages();
                };

                scope.prevPage = () => {
                    if (scope.page == 1) return;
                    scope.page -= 1;
                    updatePages();
                };
            },
        };
    },
]);
