app.controller('ContactController', [
    '$scope',
    'config',
    'api',
    'log',
    function (
        $scope,
        config,
        api,
        log,
    ) {
        $scope.contactMessage = {
            app: config.api.printicular.clientName,
            name: '',
            emailAddress: '',
            message: '',
            data: {},
        };
        $scope.submitContact = ($event) => {
            $event.preventDefault();
            $scope.contactLoading = true;
            api.post('contact', {
                data: {
                    type: 'contact',
                    attributes: $scope.contactMessage,
                },
            }).then(() => {
                $scope.contactLoading = false;
                $scope.contactSuccess = 'Successfully sent contact message.';
            }).catch((response) => {
                $scope.contactLoading = false;
                $scope.contactError = 'Failed to send contact message. ' + log.mapErrors(response);
            });
        };
    },
]);
