app.directive('pMap', [
    'Debouncer',
    'log',
    function (
        Debouncer,
        log,
    ) {
        const MARKER_WIDTH = 50;
        const MARKER_HEIGHT = 62;

        return {
            restrict: 'A',
            scope: {
                markers: '=pMapMarkers',
                bounds: '=pMapBounds',
                infoWindow: '=pMapInfoWindow',
                minZoom: '@pMapMinZoom',
                maxZoom: '@pMapMaxZoom',
                onBoundsChanged: '&pMapOnBoundsChanged',
                onClickMarker: '&pMapOnClickMarker',
            },
            link: function (scope, element) {
                element.height(550);
                scope.minZoom = parseInt(scope.minZoom) || 10;
                scope.maxZoom = parseInt(scope.maxZoom) || 16;

                const boundsChangeEventDebouncer = new Debouncer();
                const boundsChangeExternalDebouncer = new Debouncer();
                const map = new google.maps.Map(element.get(0), {
                    center: {
                        lat: -37.7862694,
                        lng: 175.2807603,
                    },
                    zoom: scope.minZoom,
                    minZoom: scope.minZoom,
                    maxZoom: scope.maxZoom,
                    streetViewControl: false,
                    scaleControl: true,
                });
                const markerQueue = [];
                let markerQueueInterval = null;
                function processMarkerQueue() {
                    for (let i = 0; i < 10; i++) {
                        if (markerQueue.length > 0) {
                            const {
                                id,
                                latitude,
                                longitude,
                                iconUrl,
                                data,
                            } = markerQueue.pop();
                            if (!markers[id]) {
                                markers[id] = new google.maps.Marker({
                                    position: new google.maps.LatLng(latitude, longitude),
                                    icon: getScaledIcon(map.getZoom(), iconUrl),
                                    map,
                                    data,
                                });
                                if (scope.infoWindow && scope.infoWindow.markerId == id) {
                                    infoWindow.open(map, markers[id]);
                                }
                                google.maps.event.addListener(markers[id], 'click', () => {
                                    log.verbose('Clicked marker', id, markers[id]);
                                    scope.onClickMarker({
                                        id: id,
                                        marker: markers[id],
                                    });
                                });
                            }
                        } else {
                            clearInterval(markerQueueInterval);
                            markerQueueInterval = null;
                            return;
                        }
                    }
                }

                // For debugging
                window.MAP = map;

                const markers = {};
                const infoWindow = new google.maps.InfoWindow({
                    content: '',
                });

                google.maps.event.addListener(map, 'bounds_changed', () => {
                    log.verbose('Map bounds changed event');
                    boundsChangeEventDebouncer.trigger(() => {
                        const bounds = map.getBounds();

                        // Hide markers out of bounds
                        for (const markerId in markers) {
                            const marker = markers[markerId];
                            marker.setVisible(bounds.contains(marker.getPosition()));
                        }

                        log.verbose('Map bounds changed internally', bounds);
                        scope.onBoundsChanged({
                            bounds: bounds,
                        });
                    });
                });

                google.maps.event.addListener(map, 'zoom_changed', () => {
                    for (const markerId in markers) {
                        const marker = markers[markerId];
                        marker.setIcon(getScaledIcon(map.getZoom(), marker.icon.url));
                    }
                });

                function getScaledIcon(zoom, url) {
                    const scale = zoom / scope.maxZoom;
                    return {
                        url: url,
                        scaledSize: new google.maps.Size(MARKER_WIDTH * scale, MARKER_HEIGHT * scale),
                        origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(MARKER_WIDTH * scale / 2, MARKER_HEIGHT * scale),
                    };
                }

                function addMarker(marker) {
                    markerQueue.push(marker);
                    if (!markerQueueInterval) {
                        markerQueueInterval = setInterval(processMarkerQueue, 1);
                    }
                }

                function zoomToFitPoints(points) {
                    log.verbose('Zooming map to fix points', points);
                    var bounds = new google.maps.LatLngBounds();
                    for (const point of points) {
                        bounds.extend(new google.maps.LatLng(point[0], point[1]));
                    }
                    map.fitBounds(bounds);
                }

                function zoomToBox(latitude1, longitude1, latitude2, longitude2) {
                    log.verbose('Zooming map to fix box', latitude1, longitude1, latitude2, longitude2);
                    var bounds = new google.maps.LatLngBounds();
                    bounds.extend(new google.maps.LatLng(latitude1, longitude1));
                    bounds.extend(new google.maps.LatLng(latitude2, longitude2));
                    map.fitBounds(bounds);
                }

                scope.$watch(() => Object.keys(scope.markers).length, () => {
                    log.verbose('Map markers changed', scope.markers);
                    const newMarkers = [];
                    for (const markerId in scope.markers) {
                        const newMarker = addMarker(scope.markers[markerId]);
                        if (newMarker) {
                            newMarkers.push(newMarker);
                        }
                    }
                });
                scope.$watch('bounds', () => {
                    boundsChangeExternalDebouncer.trigger(() => {
                        if (scope.bounds.bounds) {
                            log.verbose('Map bounds changed bounds', scope.bounds);
                            zoomToBox(...scope.bounds.bounds);
                        } else if (scope.bounds.marker) {
                            log.verbose('Map bounds changed marker', scope.bounds);
                            map.setCenter(markers[scope.bounds.marker].getPosition());
                            map.setZoom(scope.maxZoom);
                        } else if (scope.bounds.points) {
                            log.verbose('Map bounds changed points', scope.bounds);
                            zoomToFitPoints(scope.bounds.points);
                        } else if (scope.bounds.point) {
                            log.verbose('Map bounds changed point', scope.bounds, scope.minZoom + (scope.maxZoom - scope.minZoom) / 2);
                            map.setCenter(new google.maps.LatLng(scope.bounds.point[0], scope.bounds.point[1]));
                            map.setZoom(Math.round(scope.minZoom + (scope.maxZoom - scope.minZoom) / 2));
                        }
                    });
                }, true);

                scope.$watch('infoWindow', () => {
                    log.verbose('Update map info window', scope.infoWindow);
                    if (!scope.infoWindow) {
                        infoWindow.close();
                        return;
                    }
                    infoWindow.setContent(scope.infoWindow.content);
                    if (scope.infoWindow.markerId && markers[scope.infoWindow.markerId]) {
                        infoWindow.open(map, markers[scope.infoWindow.markerId]);
                    } else {
                        infoWindow.close();
                    }
                }, true);
            },
        };
    },
]);
