app.factory('CartLineItem', [
    '$rootScope',
    'browser',
    'config',
    'gtm',
    'log',
    'Unique',
    function (
        $rootScope,
        browser,
        config,
        gtm,
        log,
        Unique,
    ) {
        return class CartLineItem {
            constructor({ cart, image, product = null, quantity = 1, settings = {}}) {
                this.id = Unique.inc('line-item');
                this.cart = cart;
                this.image = image;
                this.product = product;
                this.quantity = quantity;
                this.settings = settings;
                this._previewSvg = null;

                if (!this.product) {
                    this.product = this.getDefaultProduct();
                }
            }

            get previewSvg () {
                return this._previewSvg || this.svg;
            }

            set previewSvg (svg) {
                this._previewSvg = svg;
            }

            changeQuantity() {
                if (this.quantity > config.maxQuantity) {
                    this.quantity = config.maxQuantity;
                }
                if (this.quantity < 0) {
                    this.quantity = 0;
                }
                this.cart.updateSummary();
            }

            increaseQuantity() {
                if (this.quantity < config.maxQuantity) {
                    this.quantity++;
                }
                this.changeQuantity();
            }

            decreaseQuantity() {
                if (this.quantity > 0) {
                    this.quantity--;
                }
                this.changeQuantity();
            }

            setProduct(product) {
                if (this.product && this.product.id == product.id) {
                    return false;
                }
                log.debug('Setting product to ' + product.formatName());
                this.product = product;
                this.cropRectPos = null;
                this.svg = null;
                this._previewSvg = null;
                this.settings = {};

                if (product) {
                    gtm.push({
                        event: 'Select size',
                        productName: product.formatName(),
                        productId: product.id,
                    });
                }
                this.cart.updateSummary();
                $rootScope.$broadcast('changeProduct');
                return true;
            }

            setImage(image) {
                this.image = image;
            }

            isLowRes() {
                if (!this.image || !this.image.thumbnail || !this.image.thumbnail.image || !this.product) {
                    return;
                }
                if (this.getImageWidth() < this.getProductMinWidth() || this.getImageHeight() < this.getProductMinHeight()) {
                    return true;
                }
                return false;
            }

            getProductMinWidth() {
                let threshold = this.product.minRes;
                if (!threshold) {
                    return '?';
                }
                threshold = threshold / 100;
                const productSize = this.product.getProductSize();
                return Math.max(productSize.width, productSize.height) * threshold;
            }

            getProductMinHeight() {
                let threshold = this.product.minRes;
                if (!threshold) {
                    return;
                }
                threshold = threshold / 100;
                const productSize = this.product.getProductSize();
                return Math.min(productSize.width, productSize.height) * threshold;
            }

            getProductWidth() {
                const productSize = this.product.getProductSize();
                return Math.max(productSize.width, productSize.height);
            }

            getProductHeight() {
                const productSize = this.product.getProductSize();
                return Math.min(productSize.width, productSize.height);
            }

            getImageWidth() {
                if (this.cropRectPos) {
                    return Math.round(Math.max(this.cropRectPos.width, this.cropRectPos.height));
                }
                return Math.max(this.image.thumbnail.image.width, this.image.thumbnail.image.height);
            }

            getImageHeight() {
                if (this.cropRectPos) {
                    return Math.round(Math.min(this.cropRectPos.width, this.cropRectPos.height));
                }
                return Math.min(this.image.thumbnail.image.width, this.image.thumbnail.image.height);
            }

            isAutoCropped() {
                if (this.cropRectPos || !this.product || !this.canCrop() || !this.image.thumbnail) {
                    return false;
                }
                const productSize = this.product.getProductSize();
                const imageAspectRatio = this.image.thumbnail.width < this.image.thumbnail.height ? this.image.thumbnail.width / this.image.thumbnail.height : this.image.thumbnail.height / this.image.thumbnail.width;
                const productAspectRatio = productSize.width < productSize.height ? productSize.width / productSize.height : productSize.height / productSize.width;

                return Math.abs(imageAspectRatio - productAspectRatio) > 0.01;
            }

            canCrop() {
                if (browser.isIe11) {
                    return false;
                }

                if (this.canEdit()) {
                    return false;
                }

                return this.product ? true : false;
            }

            getImageType() {
                if (this.svg) {
                    return 'svg';
                }
                if (this.image.isCanvas()) {
                    return 'canvas';
                }
                return 'img';
            }

            getImageId() {
                return this.image.getImageId();
            }

            canChangeProduct() {
                return true;
            }

            canDuplicate() {
                return true;
            }

            canEdit() {
                return this.product.productImages.filter(img => !!img.attributes.meta_data.frame).length > 0;
            }

            edit() {
                if (this.canEdit()) {
                    return $rootScope.openModal({
                        name: 'modalFrameFlow',
                        lineItem: this,
                    });
                }

                if (this.canCrop()) {
                    return $rootScope.cropLineItem(this);
                }
            }

            isErrored() {
                if (this.image && this.image.isErrored()) {
                    return true;
                }
                return false;
            }

            isReady() {
                if (this.image && this.image.isReady()) {
                    return true;
                }
                return false;
            }

            getOrderJson() {
                if (!this.quantity || !this.product || this.isErrored()) {
                    return null;
                }
                let lineItemJson = {
                    type: 'line_items',
                    attributes: {
                        product_id: this.product ? this.product.id : null,
                        quantity: Math.min(this.quantity, config.maxQuantity),
                        vertical: this.cropRectPos && this.cropRectPos.orientation == 'portrait' ? true : false,
                    },
                };
                if (this.product.metaData.frame && this.product.metaData.frame.duplicateImage) {
                    lineItemJson.attributes.image_ids = [
                        this.getImageId(),
                        this.getImageId(),
                    ];
                } else if (this.getImageId()) {
                    lineItemJson.attributes.image_id = this.getImageId();
                }

                if (this.svg) {
                    lineItemJson.attributes.svg = this.svg.replace(/xlink:href=".*?"/, 'xlink:href=""');
                }

                return lineItemJson;
            }

            getAvailableProducts() {
                const filter = (product) => {
                    if (!product.enabled) {
                        return false;
                    }
                    if (!product.prices.length) {
                        return false;
                    }
                    return true;
                };
                if (this.cart.fulfillment === 'pickup' && config.flow !== 'products-first' && this.cart.store) {
                    return this.cart.getStoreProducts()
                        .filter(filter);
                }
                // Delivery or product first flow
                return this.cart.getPrintServiceProducts()
                    .filter(filter);
            }

            getDefaultProduct() {
                const products = this.getAvailableProducts();
                if (this.product) {
                    const defaultExistingProduct = products.find((product) => {
                        return this.product.attributes.name == product.attributes.name;
                    });
                    if (defaultExistingProduct) {
                        return defaultExistingProduct;
                    }
                }
                for (const product of products) {
                    return product;
                }
                log.debug('Could not find default product', this);
            }
        };
    },
]);
