app.factory('cart', [
    '$q',
    '$rootScope',
    '$timeout',
    'api',
    'CartImage',
    'CartImageData',
    'CartLineItem',
    'CartReorderImage',
    'config',
    'gtm',
    'imageUploader',
    'Locale',
    'log',
    'Product',
    'thumbnailer',
    function (
        $q,
        $rootScope,
        $timeout,
        api,
        CartImage,
        CartImageData,
        CartLineItem,
        CartReorderImage,
        config,
        gtm,
        imageUploader,
        Locale,
        log,
        Product,
        thumbnailer,
    ) {
        let nextId = 1;

        const Cart = class {
            constructor() {
                this.lineItems = [];
                this.images = {};
                this.summary = {};
                this.store = null;
                this.deliveryProducts = null;
                this.pickupProducts = {};
                this.name = config.storageGet('name') || '';
                this.email = config.storageGet('email') || '';
                this.phone = config.storageGet('phone') || '';
                this.enableSms = config.storageGet('enableSms') !== false;
                this.addressLine1 = config.storageGet('addressLine1') || '';
                this.addressCity = config.storageGet('addressCity') || '';
                this.addressTerritoryId = config.storageGet('addressTerritoryId');
                this.addressRegion = config.storageGet('addressRegion') || '';
                this.addressPostCode = config.storageGet('addressPostCode') || '';
                this.orderNumber = null;
                this.updatingPrices = false;
                this.couponCode = null;
                this.updateSummaryDebounce = null;
                this.validations = {};

                this.fulfillment = config.storageGet('fulfillment') || 'pickup';
                if (!config.api.printicular.deliveryPrintService.id) {
                    this.fulfillment = 'pickup';
                }
            }

            addLineItem(cartLineItem) {
                log.verbose('Add line item', cartLineItem);
                this.lineItems.push(cartLineItem);
                $rootScope.$broadcast('changeProduct');
                this.updateSummary();
            }

            removeLineItem(lineItem) {
                const index = this.lineItems.indexOf(lineItem);
                if (index !== -1) {
                    this.lineItems.splice(index, 1);
                    $rootScope.$broadcast('changeProduct');
                    this.updateSummary();
                }
            }

            hasLineItems () {
                return this.lineItems.length > 0;
            }

            setStore(store, fireEvent = true) {
                log.verbose('Set store', store);
                this.currency = null;
                this.store = store;
                if (store) {
                    config.storageSet('store', store.id);
                    if (fireEvent) {
                        gtm.push({
                            event: 'Select store',
                            storeName: store.attributes.name,
                            storeId: store.id,
                        });
                    }
                }
                this.resetDefaultProducts('set store');
                this.updateSummary();
            }

            getAllProducts() {
                if (this.fulfillment === 'pickup' && config.flow !== 'products-first' && this.store) {
                    return this.getStoreProducts();
                }
                // Delivery or product first flow
                return this.getPrintServiceProducts();
            }

            setPickupProducts(pickupProducts, printServiceId) {
                this.pickupProducts[printServiceId] = this.sortProducts(pickupProducts);
                log.debug('Available pickup products', this.pickupProducts);
            }

            setDeliveryProducts(deliveryProducts) {
                this.deliveryProducts = this.sortProducts(deliveryProducts);
                log.debug('Available delivery products', this.deliveryProducts);
            }

            resetDefaultProducts(why) {
                log.debug('Reset default products ' + why);
                for (const lineItem of this.lineItems) {
                    if (!lineItem.getDefaultProduct() && !this.hasNoProducts()) {
                        if (!confirm('Are you sure you want to do this, this will remove items from your cart?')) {
                            return false;
                        }
                        break;
                    }
                }
                for (const lineItem of this.lineItems) {
                    const defaultProduct = lineItem.getDefaultProduct();
                    if (defaultProduct) {
                        lineItem.setProduct(defaultProduct);
                    } else if (!this.hasNoProducts()) {
                        this.removeLineItem(lineItem);
                    }
                }
                return true;
            }

            hasNoProducts() {
                return (
                    this.store &&
                    this.fulfillment == 'pickup' &&
                    this.store.products.length < 1
                );
            }

            sortProducts(products, sortCallback) {
                let collator = null;
                try {
                    collator = new Intl.Collator(undefined, {
                        numeric: true,
                        sensitivity: 'base',
                    });
                } catch (e) {
                    // Ignore
                }
                const currency = this.getCurrency();
                products.sort((a, b) => {
                    // Sort by cheapest first (or override sort order in config)
                    let aPrice = a.prices.find(price => price.attributes.currency === currency);
                    aPrice = aPrice && aPrice.attributes.total ? aPrice.attributes.total : 100000;
                    if (a.sortOrder) {
                        aPrice = -a.sortOrder;
                    }
                    let bPrice = b.prices.find(price => price.attributes.currency === currency);
                    bPrice = bPrice && bPrice.attributes.total ? bPrice.attributes.total : 100000;
                    if (b.sortOrder) {
                        bPrice = -b.sortOrder;
                    }
                    if (aPrice != bPrice) {
                        return aPrice - bPrice;
                    }
                    if (!collator) {
                        return 0;
                    }
                    // Sort by name if price is the same
                    const aSortAttr = sortCallback ? sortCallback(a) : a.attributes.name;
                    const bSortAttr = sortCallback ? sortCallback(b) : b.attributes.name;
                    return collator.compare(aSortAttr, bSortAttr);

                });
                return products;
            }

            updateSummary() {
                if (this.updateSummaryDebounce) {
                    $timeout.cancel(this.updateSummaryDebounce.timer);
                    this.updateSummaryDebounce.timer = $timeout(this.updateSummaryBounced.bind(this), 300);
                } else {
                    const defer = $q.defer();
                    this.updateSummaryDebounce = {
                        timer: $timeout(this.updateSummaryBounced.bind(this), 300),
                        defer: defer,
                    };
                }
                return this.updateSummaryDebounce.defer.promise;
            }

            updateSummaryBounced() {
                log.verbose('Update summary');
                nextId++;
                const updatingSummary = nextId;
                let totalQuantity = 0;
                let totalReady = 0;
                let totalUnready = 0;
                const currency = this.getCurrency();
                this.summary = {
                    rows: [],
                };
                for (const lineItem of this.lineItems) {
                    if (lineItem.isErrored()) {
                        continue;
                    }
                    if (lineItem.product) {
                        const name = lineItem.product.formatName();
                        let summaryRow = this.summary.rows.find(r => r.name == name);
                        if (!summaryRow) {
                            summaryRow = {
                                name: name,
                                product: lineItem.product,
                                quantity: 0,
                                price: 0,
                            };
                            this.summary.rows.push(summaryRow);
                        }
                        summaryRow.quantity += lineItem.quantity;

                        let price = 0;
                        if (lineItem.product) {
                            let productPrice = lineItem.product.prices.find(p => p.attributes.currency == currency);
                            if (productPrice) {
                                price = productPrice.attributes.total * summaryRow.quantity;
                            }
                        }
                        summaryRow.price += price;
                    }
                    if (lineItem.isReady()) {
                        totalReady++;
                    } else {
                        totalUnready++;
                    }

                    totalQuantity += lineItem.quantity;
                }

                this.summary.totalPrice = null;
                this.summary.totalQuantity = totalQuantity;
                this.summary.totalUnready = totalUnready;
                this.summary.totalReady = totalReady;
                this.summary.totalUploadedPercent = 1;

                this.validateLineItem();

                if (totalReady + totalUnready > 0) {
                    this.summary.totalUploadedPercent = Math.max(totalReady / (totalReady + totalUnready), 0.1);
                }

                if (this.summary.totalQuantity && !this.orderNumber) {
                    window.onbeforeunload = () => true;
                } else {
                    window.onbeforeunload = null;
                }

                log.verbose('Cart summary', this.summary);

                if (this.summary.totalQuantity > 0 && this.summary.totalUnready == 0) {
                    if (this.fulfillment == 'delivery') {
                        this.dryRunErrors = this.validate(true);
                        if (this.dryRunErrors.length) {
                            this.resolveUpdateSummary();
                            return;
                        }
                    }
                    const orderJson = this.getOrderJson(true);
                    if (orderJson) {
                        this.updatingPrices = true;
                        api.post('users/0/orders?include=store,lineItems,products', orderJson).then((response) => {
                            if (updatingSummary != nextId) {
                                return;
                            }
                            this.updatingPrices = false;
                            const serverSummaryRows = [];
                            for (let i = 0; i < response.included.length; i++) {
                                if (response.included[i].type == 'line_items') {
                                    const productId = response.included[i].attributes.product_id;
                                    const productData = response.included.find(i => i.type == 'products' && i.id == productId);
                                    if (productData) {
                                        const product = Product.fromJsonApi(productData, response.included);
                                        const name = product.formatName();
                                        let serverSummaryRow = serverSummaryRows.find(r => r.name == name);
                                        if (!serverSummaryRow) {
                                            serverSummaryRow = {
                                                name: name,
                                                product: product,
                                                quantity: 0,
                                                price: 0,
                                            };
                                            serverSummaryRows.push(serverSummaryRow);
                                        }
                                        serverSummaryRow.price += response.included[i].attributes.total;
                                        serverSummaryRow.quantity += response.included[i].attributes.quantity;
                                    }
                                }
                            }
                            this.summary.rows = serverSummaryRows;
                            this.summary.shipping = response.data.attributes.freight_total;
                            this.summary.shippingDiscount = response.data.attributes.freight_discount;
                            this.summary.totalPrice = response.data.attributes.total;
                            this.summary.totalDiscount = response.data.attributes.vendor_discount || response.data.attributes.subtotal_discount;
                            this.summary.couponCode = this.couponCode;
                            this.validateLineItem();
                            this.resolveUpdateSummary();
                        }).catch((response) => {
                            this.updatingPrices = false;
                            this.dryRunErrors = log.mapErrors(response);
                            log.debug('Dry run error', response, this.dryRunErrors);
                            this.validateLineItem();
                            this.resolveUpdateSummary();
                        });
                        return;
                    } else {
                        log.verbose('Not fetching prices, order JSON empty', orderJson);
                    }
                }
                this.resolveUpdateSummary();
            }

            resolveUpdateSummary() {
                if (this.updateSummaryDebounce) {
                    this.updateSummaryDebounce.defer.resolve();
                    this.updateSummaryDebounce = null;
                }
            }

            validateLineItem() {
                let lineItemErrors = [];

                const maxTotalQuantity = this.getMaxTotalQuantity();
                if (maxTotalQuantity && this.summary.totalQuantity && this.summary.totalQuantity > maxTotalQuantity) {
                    lineItemErrors.push(`The maximum number of items per order is ${maxTotalQuantity}. Please remove ${this.summary.totalQuantity - maxTotalQuantity} to continue.`);
                }

                const maxTotalValue = this.getMaxTotalValue();
                if (maxTotalValue && this.summary.totalPrice && this.summary.totalPrice > maxTotalValue) {
                    lineItemErrors.push(`The maximum order value is $ ${maxTotalValue}. Please remove some items to continue.`);
                }

                const categoryCounts = {};
                for (const lineItem of this.lineItems) {
                    if (!lineItem.product || !(lineItem.product.limit > 0)) {
                        continue;
                    }
                    if (!categoryCounts[lineItem.product.attributes.category_id]) {
                        categoryCounts[lineItem.product.attributes.category_id] = {
                            id: lineItem.product.attributes.category_id,
                            name: lineItem.product.categoryName,
                            count: 0,
                            limit: lineItem.product.limit,
                        };
                    }
                    categoryCounts[lineItem.product.attributes.category_id].count += lineItem.quantity;
                }
                for (const id in categoryCounts) {
                    if (categoryCounts[id].count > categoryCounts[id].limit) {
                        lineItemErrors.push(`The maximum number of ${categoryCounts[id].name} per order is ${categoryCounts[id].limit}. Please remove ${categoryCounts[id].count - categoryCounts[id].limit} to continue.`);
                    }
                }

                for (const key in this.validations) {
                    this.validations[key](lineItemErrors);
                }

                this.lineItemErrors = lineItemErrors;
            }

            getPostCodeType() {
                if (this.store) {
                    let territory = $rootScope.territories ? $rootScope.territories.find(territory => territory.id == this.store.attributes.territory_id) : null;
                    if (territory && territory.attributes.country_code == 'US') {
                        return 'zip';
                    }
                }
                if (this.addressCountry && this.addressCountry.attributes.country_code == 'US') {
                    return 'zip';
                }
                return 'postcode';
            }

            getCurrency() {
                if (this.currency) {
                    return this.currency;
                }
                const productCurrencies = this.getProductCurrencies();
                const userCurrency = this.getUserCurrency(productCurrencies);

                if (userCurrency) {
                    log.verbose('Using user currency', userCurrency);
                    this.currency = userCurrency;
                    return this.currency;
                }

                if (productCurrencies[0]) {
                    log.verbose('Using common product currency', productCurrencies);
                    this.currency = productCurrencies[0];
                    return this.currency;
                }

                if (config.locale.currency) {
                    log.verbose('Using config currency', config.locale.currency);
                    this.currency = config.locale.currency;
                    return this.currency;
                }

                log.error('Could not get currency from products or config, defaulting to USD');
                this.currency = 'USD';
                return this.currency;
            }

            getUserCurrency(productCurrencies) {
                if (!this.addressCountry || !this.addressCountry.attributes.country_code) {
                    return null;
                }
                const userCurrency = Locale.getCountryCurrency(this.addressCountry.attributes.country_code);
                if (productCurrencies.indexOf(userCurrency) !== -1) {
                    return userCurrency;
                }
                return null;
            }

            getProductCurrencies() {
                const currencies = {};
                let retailerId = null;
                if (this.store && this.store.attributes.retailer_id) {
                    retailerId = this.store.attributes.retailer_id || null;
                }
                log.verbose('Store retailer', retailerId, this.store);
                for (const lineItem of this.lineItems) {
                    if (!lineItem.product) {
                        continue;
                    }
                    for (const price of lineItem.product.prices) {
                        if (retailerId != (price.attributes.retailer_id || null) || !price.attributes.currency) {
                            continue;
                        }
                        if (!currencies[price.attributes.currency]) {
                            currencies[price.attributes.currency] = 0;
                        }
                        currencies[price.attributes.currency]++;
                    }
                }
                log.verbose('Currencies', currencies);
                return Object.keys(currencies).sort((a, b) => currencies[a] - currencies[b]);
            }

            getMaxTotalQuantity() {
                return this.getMaxValueByFulfillmentType(config.pickupMaxTotalQuantity, config.deliveryMaxTotalQuantity);
            }

            getMaxTotalValue() {
                return this.getMaxValueByFulfillmentType(config.pickupMaxTotalValue, config.deliveryMaxTotalValue);
            }

            getMaxValueByFulfillmentType(pickupValue, deliveryValue) {
                if (this.fulfillment == 'pickup' && pickupValue) {
                    return pickupValue;
                }
                if (this.fulfillment == 'delivery' && deliveryValue) {
                    return deliveryValue;
                }
                return false;
            }

            validate(dryRun = false) {
                const errors = [];
                if (this.summary.totalQuantity == 0) {
                    errors.push('You must have at least 1 print to continue.');
                }
                if (!dryRun && !this.name) {
                    errors.push('Your full name is required.');
                }
                if (!dryRun) {
                    if (!this.phone) {
                        errors.push('Your phone number is required.');
                    } else {
                        if (!this.validatePhoneNumber(this.phone)) {
                            errors.push('Your phone number is invalid.');
                        }
                    }
                }
                if (!dryRun) {
                    if (!this.email) {
                        errors.push('Your email address is required.');
                    } else {
                        if (!this.validateEmail(this.email)) {
                            errors.push('Your email is invalid.');
                        }
                    }
                }
                if (this.fulfillment == 'delivery') {
                    if (!this.addressLine1) {
                        errors.push('Your shipping address is required.');
                    }
                    if (!this.addressCity) {
                        errors.push('Your shipping address city is required.');
                    }
                    if (!this.addressPostCode) {
                        errors.push('Your shipping address postcode is required.');
                    }
                    if (!this.addressRegion) {
                        errors.push('Your shipping address state/region is required.');
                    }
                    if (!this.addressCountry || !this.addressCountry.id) {
                        errors.push('Your shipping address country is required.');
                    }
                }
                log.verbose('Cart validation', errors);
                return errors;
            }

            validateEmail(email) {
                var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            }

            validatePhoneNumber(phoneNumber) {
                return this.cleanPhoneNumber(phoneNumber).length > 4;
            }

            cleanPhoneNumber(phoneNumber) {
                return phoneNumber.replace(/[^0-9]+/g, '');
            }

            getOrderJson(dryRun = true, stripeToken) {
                if (this.fulfillment == 'pickup' && !this.store) {
                    log.verbose('No store when pickup, cant make order JSON');
                    return null;
                }

                config.storageSet('name', this.name);
                config.storageSet('email', this.email);
                config.storageSet('phone', this.phone);
                config.storageSet('enableSms', this.enableSms);
                config.storageSet('addressLine1', this.addressLine1);
                // config.storageSet('addressLine2', this.addressLine2);
                config.storageSet('addressCity', this.addressCity);
                config.storageSet('addressTerritoryId', this.addressCountry ? this.addressCountry.id : null);
                config.storageSet('addressRegion', this.addressRegion);
                config.storageSet('addressPostCode', this.addressPostCode);
                const addressJson = {
                    phone: dryRun ? '1234567890' : this.cleanPhoneNumber(this.phone),
                    email: dryRun ? 'dryrun@example.com' : this.email,
                    name: dryRun ? 'Dry Run' : this.name,
                };
                if (this.fulfillment == 'delivery') {
                    addressJson.line_1 = this.addressLine1;
                    addressJson.city = this.addressCity;
                    addressJson.state = this.addressRegion && this.addressRegion.code ? this.addressRegion.code : this.addressRegion;
                    addressJson.postcode = this.addressPostCode;
                    addressJson.territory_id = this.addressCountry ? this.addressCountry.id : null;
                }
                const meta = {
                    device_token: config.deviceToken,
                    dryrun: dryRun,
                    auto_confirm: !dryRun,
                };
                if (this.couponCode) {
                    meta.coupon_code = this.couponCode;
                }
                const order = {
                    meta: meta,
                    data: {
                        type: 'orders',
                        attributes: {
                            client_version: config.version.toString(),
                            client_name: config.api.printicular.clientName,
                            currency: this.getCurrency(),
                            stripe_token: stripeToken,
                            enable_sms: this.enableSms && this.getPrintServiceSettings().enableSms,
                        },
                        relationships: {
                            print_service: {
                                data: {
                                    id: this.fulfillment == 'pickup' ? this.store.printServiceId : config.api.printicular.deliveryPrintService.id,
                                    type: 'print_services',
                                },
                            },
                            line_items: {
                                data: this.lineItems
                                    .map(lineItem => lineItem.getOrderJson())
                                    .filter(lineItem => lineItem != null),
                            },
                            address: {
                                data: {
                                    type: 'addresses',
                                    attributes: addressJson,
                                },
                            },
                        },
                    },
                };
                if (this.fulfillment == 'pickup' && this.store) {
                    order.data.relationships.store = {
                        data: {
                            type: 'stores',
                            id: this.store.id,
                        },
                    };
                }
                if (!order.data.relationships.line_items.data || !order.data.relationships.line_items.data.length) {
                    log.verbose('No line item data, cant make order JSON');
                    return null;
                }
                return order;
            }

            togglePickup() {
                if (this.fulfillment === 'pickup') {
                    return;
                }
                this.currency = null;
                this.fulfillment = 'pickup';
                if (!this.resetDefaultProducts('toggle pickup')) {
                    this.fulfillment = 'delivery';
                    return;
                }
                config.storageSet('fulfillment', this.fulfillment);
            }

            toggleDelivery() {
                if (this.fulfillment === 'delivery') {
                    return;
                }
                this.currency = null;
                this.fulfillment = 'delivery';
                if (!this.resetDefaultProducts('toggle delivery')) {
                    this.fulfillment = 'pickup';
                    return;
                }
                config.storageSet('fulfillment', this.fulfillment);
            }

            getStoreProducts() {
                return this.pickupProducts[this.store.printServiceId].filter((printServiceProduct) => {
                    return this.store.products.find(storeProduct => storeProduct.id == printServiceProduct.id);
                });
            }

            getPrintServiceSettings() {
                if (this.fulfillment == 'delivery') {
                    return config.api.printicular.deliveryPrintService;
                }
                if (config.api.printicular.pickUpPrintServices.length == 1) {
                    return config.api.printicular.pickUpPrintServices[0];
                }
                if (this.store) {
                    return config.api.printicular.pickUpPrintServices.find(printService => printService.id == this.store.printServiceId);
                }
            }

            getPrintServiceProducts() {
                if (this.fulfillment === 'pickup') {
                    for (const printServiceId in this.pickupProducts) {
                        return this.pickupProducts[printServiceId];
                    }
                    log.debug('Could not find print service products', this.pickupProducts);
                    return [];
                }
                return this.deliveryProducts;
            }

            getLineItemCount() {
                let count = 0;
                for (const lineItem of this.lineItems) {
                    if (!lineItem.isErrored()) {
                        count++;
                    }
                }
                return count;
            }

            addImage(file) {
                if (!file.type.startsWith('image/')) {
                    $rootScope.uploadErrors.push('Could not upload ' + file.name + ' because it is not a supported image type.');
                    return false;
                }
                const key = thumbnailer.getFileKey(file);
                if (this.images[key] && this.images[key].isErrored()) {
                    delete this.images[key];
                }
                if (!this.images[key]) {
                    this.images[key] = new CartImage(key, file, imageUploader.addFile(file), thumbnailer.addFile(file));
                    this.images[key].upload.load.finally(() => {
                        this.updateSummary();
                    });
                }
                return this.images[key];
            }

            addImageData(data, name, mimeType) {
                const key = md5(data);
                if (this.images[key] && this.images[key].isErrored()) {
                    delete this.images[key];
                }
                if (!this.images[key]) {
                    const file = new Blob([data], {
                        type: mimeType,
                        lastModified: new Date(),
                    });
                    file.name = name;
                    this.images[key] = new CartImageData(key, data, imageUploader.addFile(file));
                    this.images[key].upload.load.finally(() => {
                        this.updateSummary();
                    });
                }
                return this.images[key];
            }

            removeImage(image) {
                delete this.images[image.key];
                this.lineItems = this.lineItems.filter(lineItem => lineItem.image && lineItem.image.key != image.key);
                this.updateSummary();
            }

            getLowResCount() {
                let count = 0;
                for (const lineItem of this.lineItems) {
                    if (lineItem.isLowRes()) {
                        count++;
                    }
                }
                return count;
            }

            reorder(orderId) {
                const order = config.storageGet('reorder');
                if (order.id != orderId) {
                    return;
                }
                const pickupPrintService = config.api.printicular.pickUpPrintServices.find(printService => printService.id == order.printServiceId);
                if (pickupPrintService) {
                    this.togglePickup();
                }
                if (order.printServiceId == config.api.printicular.deliveryPrintService.id) {
                    this.toggleDelivery();
                }
                this.name = order.name || this.name;
                this.email = order.emailAddress || this.email;
                this.phone = order.phoneNumber || this.phone;
                for (const lineItem of order.lineItems) {
                    const key = lineItem.checksum;
                    if (!this.images[key]) {
                        this.images[key] = new CartReorderImage(key, lineItem.thumbnail, lineItem.imageId);
                    }
                    let product = this.getPrintServiceProducts().find((product) => {
                        return product.id == lineItem.productId;
                    });
                    if (!product) {
                        continue;
                    }
                    this.lineItems.push(new CartLineItem({
                        cart,
                        product,
                        image: this.images[key],
                        quantity: lineItem.quantity,
                    }));
                }
                $rootScope.$broadcast('changeProduct');
                this.updateSummary();
            }
        };

        const cart = new Cart();
        $rootScope.cart = cart;
        window.CART = cart;
        return cart;
    },
]);
