app.directive('pElastic', ['$timeout', function ($timeout) {
    return {
        restrict: 'A',
        link: function link($scope, element, attributes) {

            $scope.initialHeight = $scope.initialHeight || element[0].style.height;
            var resize = function resize() {
                element[0].rows = Math.max(attributes.pElastic || 3, element[0].value.split(/\r*\n/).length);
            };
            element.on('input change', resize);
            $timeout(resize, 0);
        },
    };
}]);
