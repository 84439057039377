app.factory('query', [
    function (
    ) {
        const parseQueryString = (query) => {
            const obj = {};
            const qPos = query.indexOf('?');
            const tokens = query.substr(qPos + 1).split('&');
            let i = tokens.length - 1;
            if (qPos !== -1 || query.indexOf('=') !== -1) {
                for (; i >= 0; i--) {
                    var s = tokens[i].split('=');
                    obj[unescape(s[0])] = s.hasOwnProperty(1) ? unescape(s[1]) : null;
                }
            }
            return obj;
        };

        return parseQueryString(window.location.search);
    },
]);
