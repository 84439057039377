app.factory('Location', [
    'BaseModel',
    'Store',
    function (
        BaseModel,
        Store,
    ) {
        return class Location extends BaseModel {
            static isType(type) {
                return type == 'Location';
            }

            get stores() {
                if (!this._stores) {
                    this._stores = this.findAllRelated('stores', Store);
                }
                return this._stores;
            }
        };
    },
]);
