app.factory('Product', [
    'BaseModel',
    'Price',
    'ProductTag',
    'ProductImage',
    'config',
    function (
        BaseModel,
        Price,
        ProductTag,
        ProductImage,
        config,
    ) {
        return class Product extends BaseModel {
            static isType(type) {
                return type == 'products' || type == 'product';
            }

            get prices() {
                if (!this._prices) {
                    this._prices = this.findAllRelated('prices', Price);
                }
                return this._prices;
            }

            get productTags() {
                if (!this._productTags) {
                    this._productTags = this.findAllRelated(['product_tags', 'productTags'], ProductTag);
                }
                return this._productTags;
            }

            get productImages() {
                if (!this._productImages) {
                    this._productImages = this.findAllRelated(['product_images', 'productImages'], ProductImage);
                }
                return this._productImages;
            }

            hasTags(tags) {
                if (!this.productTags || !this.productTags.length) {
                    return false;
                }

                for (const productTag of this.productTags) {
                    let productTagName = productTag.attributes.name.toLowerCase();
                    for (const tag of tags) {
                        let tagName = tag.attributes ? tagName.attributes.name : tag.name;
                        if (!tagName) {
                            continue;
                        }
                        tagName = tagName.toLowerCase();

                        if (productTagName === tagName) {
                            return true;
                        }
                    }
                }

                return false;
            }

            isPromoProduct() {
                return this.hasTags(config.promo.productTags);
            }

            getRelatedProducts(products) {
                return products.filter(product => {
                    return product.formatName() === this.formatName();
                });
            }

            getProductSize() {
                let width = null;
                let height = null;
                let ratio = null;
                if (this.attributes.pixel_width && this.attributes.pixel_height) {
                    width = this.attributes.pixel_width;
                    height = this.attributes.pixel_height;
                } else if (this.attributes.metric && this.attributes.width && this.attributes.height) {
                    width = (this.attributes.width / 25.4) * 300;
                    height = (this.attributes.height / 25.4) * 300;
                } else if (this.attributes.width && this.attributes.height) {
                    width = this.attributes.width * 300;
                    height = this.attributes.height * 300;
                }

                ratio = width < height ? width / height : height / width;

                return {
                    width,
                    height,
                    ratio,
                };
            }

            get printServiceSettings() {
                if (!this._printServiceSettings) {
                    if (config.api.printicular.deliveryPrintService.id == this.attributes.print_service_id) {
                        this._printServiceSettings = config.api.printicular.deliveryPrintService;
                    } else {
                        this._printServiceSettings = config.api.printicular.pickUpPrintServices.find(printService => printService.id == this.attributes.print_service_id);
                    }
                    if (!this._printServiceSettings) {
                        this._printServiceSettings = {};
                    }
                }
                return this._printServiceSettings;
            }

            get settings() {
                if (!this._settings) {
                    this._settings = {};
                    if (this.printServiceSettings && this.printServiceSettings.productSettings) {
                        this._settings = this.printServiceSettings.productSettings[this.id] || {};
                    }
                }
                return this._settings;
            }

            formatName() {
                return this.settings.name || this.attributes.short_description || this.attributes.name;
            }

            get enabled() {
                if (this.printServiceSettings.autoEnableProducts) {
                    return true;
                }
                return this.settings.enabled;
            }

            get minRes() {
                return this.settings.minRes;
            }

            get sortOrder() {
                return this.settings.sortOrder;
            }

            get description() {
                return this.settings.description;
            }

            get categorySettings() {
                if (!this._categorySettings) {
                    this._categorySettings = {};
                    if (this.printServiceSettings && this.printServiceSettings.categorySettings) {
                        this._categorySettings = this.printServiceSettings.categorySettings[this.attributes.category_id] || {};
                    }
                }
                return this._categorySettings;
            }

            get limit() {
                return this.categorySettings.limit;
            }

            get categoryName() {
                return this.categorySettings.name || this.attributes.category_name;
            }

            get metaData() {
                return this.attributes.meta_data ? this.attributes.meta_data : {};
            }
        };
    },
]);
