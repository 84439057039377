app.directive('pModalImage', [
    'thumbnailer',
    function (
        thumbnailer,
    ) {
        return {
            restrict: 'A',
            replace: true,
            scope: {
                image: '=pModalImage',
            },
            link: function (scope, element) {
                scope.$watch('image', (image) => {
                    element.hide();
                    if (!image) {
                        return;
                    }
                    let resized = thumbnailer.addFile(image.file);
                    resized.load.then(() => {
                        let width = resized.width;
                        let height = resized.height;
                        if (resized.width > 800) {
                            height = (height / width) * 800;
                            width = 800;
                        }
                        if (resized.height > 800) {
                            width = (width / height) * 800;
                            height = 800;
                        }
                        const context = element[0].getContext('2d');
                        element[0].width = width;
                        element[0].height = height;
                        thumbnailer.drawImageCover(context, resized.canvas);
                        element.show();
                    });
                });
            },
        };
    },
]);
