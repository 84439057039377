app.factory('FitText', [
    'log',
    'svg',
    function (
        log,
        svg,
    ) {
        return class FitText {
            static fitText(wrapper, boundingBox, maxFontSize, lines, align = 'center') {
                log.verbose('Fit text', wrapper, boundingBox, maxFontSize, lines, align);
                if (!lines || !lines.length) {
                    return;
                }
                if (!wrapper) {
                    log.error('Text wrapper is not ready');
                    return {};
                }
                while (wrapper.firstChild) {
                    wrapper.removeChild(wrapper.firstChild);
                }

                let offsetX = 0;
                if (align == 'center') {
                    offsetX = boundingBox.width / 2;
                } else if (align == 'left') {
                    offsetX = 0;
                } else if (align == 'right') {
                    offsetX = boundingBox.width;
                }
                let offsetY = 0;
                const boundingBoxHeight = boundingBox.height;
                log.verbose('Fit text offset', offsetX, offsetY);
                let nodes = [];
                let minFontSize = 100000;
                for (let i = 0; i < lines.length; i++) {
                    let node = document.createElementNS('http://www.w3.org/2000/svg', 'text');
                    let lineText = lines[i] || '\xa0';
                    log.verbose('Fit line', lineText, boundingBox, offsetX, offsetY);
                    node.textContent = lineText;
                    node.setAttribute('class', 'p-fit-text-line-' + (i + 1));
                    node.setAttribute('x', boundingBox.x + offsetX);
                    node.setAttribute('y', boundingBox.y + offsetY);
                    node.setAttribute('style', 'font-size: ' + Math.min(maxFontSize, boundingBoxHeight / lines.length) + 'px');
                    nodes.push(node);
                    wrapper.append(node);
                    let fontSize = parseInt(getComputedStyle(node).fontSize);

                    minFontSize = Math.min(minFontSize, fontSize);
                    let box = svg.getBBox(node);
                    while (box.height > boundingBoxHeight / lines.length || box.width > boundingBox.width) {
                        fontSize--;
                        node.style.fontSize = fontSize + 'px';
                        minFontSize = Math.min(minFontSize, fontSize);
                        if (fontSize <= 2) {
                            log.verbose('Min font size reached');
                            break;
                        }
                        box = svg.getBBox(node);
                    }

                    offsetY += box.height;
                }
                for (var i = 0; i < nodes.length; i++) {
                    nodes[i].style.fontSize = minFontSize + 'px';
                }
                let wrapperBox = svg.getBBox(wrapper);
                wrapper.style.transform = `translate(0, ${(boundingBox.height / 2) - (wrapperBox.height / 2)}px)`;
                FitText.fixLineHeight(wrapper);
                return {
                    nodes,
                    minFontSize,
                };
            }

            static fixLineHeight(wrapper) {
                const nodes = wrapper.querySelectorAll('text');
                for (let i = 1; i < nodes.length; i++) {
                    let previousBox = svg.getBBox(nodes[i - 1]);
                    let box = svg.getBBox(nodes[i]);
                    let gap = box.y - (previousBox.y + previousBox.height);
                    if (gap > 50) {
                        nodes[i].setAttribute('y', previousBox.y + previousBox.height + 50);
                    }
                }
            }
        };
    },
]);
