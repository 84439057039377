app.factory('LineItem', [
    'BaseModel',
    'Product',
    'Image',
    function (
        BaseModel,
        Product,
        Image,
    ) {
        return class LineItem extends BaseModel {
            static isType(type) {
                return type == 'line_items' || type == 'lineitem';
            }

            static createInstance(id, type, attributes, relationships, included, meta) {
                const lineItem = super.createInstance(id, type, attributes, relationships, included, meta);
                lineItem.product = lineItem.findRelated('product', Product);
                lineItem.processedImage = lineItem.findRelated('processedImage', Image);
                lineItem.attributes.total = parseFloat(lineItem.attributes.total);
                return lineItem;
            }

            formatName() {
                return this.product ? this.product.formatName() : 'Line Item #' + this.id;
            }
        };
    },
]);
