app.controller('RootController', [
    '$rootScope',
    '$sce',
    'browser',
    'cart',
    'config',
    'gtm',
    'log',
    'login',
    'PrintService',
    'query',
    'US_STATES',
    function (
        $rootScope,
        $sce,
        browser,
        cart,
        config,
        gtm,
        log,
        login,
        PrintService,
        query,
        US_STATES,
    ) {
        window.ROOT = $rootScope;

        $rootScope.US_STATES = US_STATES;

        const abSource = document.cookie.match(/X-Source=(.*?)(;|$)/);
        log.debug('AB test', abSource);
        if (abSource) {
            gtm.push({
                abTest: abSource[1],
            });
        }

        if (query.store) config.storageSet('store', query.store);
        if (query.hasOwnProperty('lat') || query.hasOwnProperty('lon')) {
            config.storageSet('address', {
                lat: query.lat || 0,
                lon: query.lon || 0,
            });
        }

        $rootScope.config = config;
        $rootScope.lang = LANG;
        $rootScope.time = new Date().getTime();
        $rootScope.printServices = {};
        $rootScope.login = login;
        $rootScope.uploadErrors = [];
        $rootScope.browser = browser;

        $rootScope.showPromo = config.promo.enabled && $rootScope.time >= config.promo.from && $rootScope.time <= config.promo.to;
        $rootScope.promoContent = $sce.trustAsHtml(config.promo.banner);

        const promises = [];
        const productTags = (config.promo.productTags || []).concat(CONFIG.api.printicular.tags || []);
        for (const printService of config.api.printicular.pickUpPrintServices) {
            log.debug('Fetching products for print service', printService.id);
            promises.push(PrintService.findById(printService.id, productTags).then((printService) => {
                $rootScope.printServices[printService.id] = printService;
                cart.setPickupProducts(printService.products, printService.id);
            }).catch((e) => {
                log.error('Could not find pick up products for ' + printService.id, e);
            }));
        }
        if (config.api.printicular.deliveryPrintService.id) {
            log.debug('Fetching products for delivery service', config.api.printicular.deliveryPrintService.id);
            promises.push(PrintService.findById(config.api.printicular.deliveryPrintService.id, productTags).then((printService) => {
                $rootScope.printServices[config.api.printicular.deliveryPrintService.id] = printService;
                cart.setDeliveryProducts(printService.products);
            }).catch((e) => {
                log.error('Could not find delivery products.', e);
            }));
        }

        Promise.all(promises).finally(() => {
            $rootScope.$broadcast('loadedProducts');
            if (query.reorder) {
                cart.reorder(query.reorder);
            }
        });

        $('body').on('click', 'a', function () {
            const href = $(this).attr('href');
            if (href && href[0] !== '#') {
                if (cart.lineItems.length) {
                    window.open(href);
                    return false;
                }
            }
        });

        $(document).on('keydown', (event) => {
            log.verbose('Key press', event.keyCode);
            switch (event.keyCode) {
                case 27:
                    $rootScope.$broadcast('escape');
                    break;
            }
            $rootScope.$apply();
        });

        $(window).resize(() => {
            $rootScope.$broadcast('resize');
        });

        $rootScope.showImageModal = (image) => {
            if (!image.canEnlarge()) {
                return false;
            }
            $rootScope.modalImage = image;
            return true;
        };

        $rootScope.cropLineItem = (lineItem) => {
            if (!lineItem.canCrop()) {
                return false;
            }
            $rootScope.modalCrop = lineItem;
            return true;
        };

        $rootScope.closeModal = () => {
            $rootScope.modalImage = null;
            $rootScope.modalCrop = null;
            $rootScope.modalFrameFlow = null;
        };

        $rootScope.openModal = ({ name, lineItem }) => {
            $rootScope.closeModal();
            $rootScope[name] = lineItem;
        };

        $rootScope.$on('escape', () => {
            $rootScope.closeModal();
        });

        $rootScope.goto = (id) => {
            setTimeout(() => {
                const offset = $(`#${id}`).offset();
                log.verbose('Goto', id, offset, $(`#${id}`));
                if (offset && offset.top) {
                    // Scroll after element becomes visible
                    $('html, body').animate({
                        scrollTop: offset.top,
                    }, 500);
                }
            }, 1);
        };

        $rootScope.gotoNext = ($event) => {
            let next = false;
            let nextSection = null;
            for (const element of $('.p-btn-next, .p-next-target')) {
                if (next && $(element).is('.p-next-target') && $(element).is(':visible')) {
                    nextSection = element;
                    break;
                } else if (element == $event.target) {
                    next = true;
                }
            }
            if (nextSection) {
                $('html, body').animate({
                    scrollTop: $(nextSection).offset().top,
                }, 500);
            }
        };

        $rootScope.gotoPrevious = ($event) => {
            let previousSection = null;
            for (const element of $('.p-btn-next, .p-next-target')) {
                if (element == $event.target) {
                    break;
                }
                if ($(element).is('.p-next-target') && $(element).is(':visible')) {
                    previousSection = element;
                }
            }
            if (previousSection) {
                $('html, body').animate({
                    scrollTop: $(previousSection).offset().top,
                }, 500);
            } else {
                $('html, body').animate({
                    scrollTop: 0,
                }, 500);
            }
        };
    },
]);
