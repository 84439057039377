app.factory('ProductImage', [
    'BaseModel',
    function (
        BaseModel,
    ) {
        return class ProductImage extends BaseModel {
            static isType(type) {
                return type == 'product_images' || type == 'productimage';
            }

            get metaData() {
                return this.attributes.meta_data || {};
            }
        };
    },
]);
