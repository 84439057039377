app.directive('pLoader', [
    function () {
        return {
            restrict: 'EA',
            replace: true,
            template: `
                <div class="p-loader-logo">
                    <div class="p-loader-logo-tile"></div>
                    <div class="p-loader-logo-tile"></div>
                    <div class="p-loader-logo-tile"></div>
                    <div class="p-loader-logo-tile"></div>
                    <div class="p-loader-logo-tile"></div>
                </div>
            `,
        };
    },
]);
