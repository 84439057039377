app.factory('cropHelpers', [
    'Unique',
    'CropRectPos',
    function (
        Unique,
        CropRectPos,
    ) {
        const CROP_TEMPLATE = `
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 100 100">
                <image
                    class="p-crop-image"
                    x="0"
                    y="0"
                    width="100"
                    height="100"
                    preserveAspectRatio="none"
                    xlink:href="${window.TRANSPARENT_PIXEL}"
                />
            </svg>
        `;
        return {
            render (lineItem) {
                const html = $('<div>').append($.parseHTML(CROP_TEMPLATE));
                const svg = html.find('svg');
                const image = html.find('.p-crop-image');
                const cropRectPos = lineItem.CropRectPos || new CropRectPos();
                let view = {
                    x: 0,
                    y: 0,
                    width: 0,
                    height: 0,
                };

                if (!cropRectPos.orientation) {
                    cropRectPos.orientation = (lineItem.image.thumbnail.width > lineItem.image.thumbnail.height)
                        ? 'landscape'
                        : 'portait';
                }

                if (cropRectPos.rotation == 1 || cropRectPos.rotation == 3) {
                    view.width = lineItem.image.thumbnail.height;
                    view.height = lineItem.image.thumbnail.width;
                } else {
                    view.width = lineItem.image.thumbnail.width;
                    view.height = lineItem.image.thumbnail.height;
                }
                view.x = -view.width / 2;
                view.y = -view.height / 2;

                svg.attr({
                    viewBox: `${view.x} ${view.y} ${view.width} ${view.height}`,
                    width: view.width,
                    height: view.height,
                });

                image.attr({
                    'xlink:href': lineItem.image.thumbnail.image.src,
                    width: lineItem.image.thumbnail.width,
                    height: lineItem.image.thumbnail.height,
                }).css({
                    transformOrigin: `${lineItem.image.thumbnail.width / 2}px ${lineItem.image.thumbnail.height / 2}px`,
                    transform: `translate(-${lineItem.image.thumbnail.width / 2}px, -${lineItem.image.thumbnail.height / 2}px) rotate(${cropRectPos.rotation * 90}deg)`,
                });

                cropRectPos.productSize = lineItem.product.getProductSize();
                let cropWidth = view.width;
                let cropHeight = view.height;
                const ratio = view.width / view.height;
                const productRatio = cropRectPos.productWidth / cropRectPos.productHeight;
                if (ratio < productRatio) {
                    cropHeight = cropRectPos.productHeight / cropRectPos.productWidth * view.width;
                } else {
                    cropWidth = cropRectPos.productWidth / cropRectPos.productHeight * view.height;
                }
                if (!cropRectPos.fixedX && !cropRectPos.fixedY && !cropRectPos.floatX && !cropRectPos.floatY) {
                    cropRectPos.fixedX = -cropWidth / 2;
                    cropRectPos.fixedY = -cropHeight / 2;
                    cropRectPos.floatX = cropRectPos.fixedX + cropWidth;
                    cropRectPos.floatY = cropRectPos.fixedY + cropHeight;
                }

                const aspect = cropRectPos.getAspectRect();
                svg.removeAttr('width');
                svg.removeAttr('height');
                svg.attr('viewBox', `${aspect.x} ${aspect.y} ${aspect.width} ${aspect.height}`);

                return $('<div>').append(svg).html();
            },
        };
    },
]);
