app.factory('BaseModel', [
    'log',
    function (
        log,
    ) {
        const toCamelCase = (name) => {
            return name.charAt(0).toLowerCase() + name.slice(1);
        };

        const toSpaceCase = (name) => {
            return name.replace(/(?:^|\.?)([A-Z])/g, (x, y) => ' ' + y.toLowerCase()).replace(/^ /, '');
        };

        const toDashCase = (name) => {
            return name.replace(/(?:^|\.?)([A-Z])/g, (x, y) => '-' + y.toLowerCase()).replace(/^-/, '');
        };

        const BaseModel = class {
            constructor() {
                this.type = this.constructor.type;
                this.attributes = {};
                this.relationships = {};
                this.meta = {};
                this.included = [];
            }

            static fromJsonApi(modelData, includedData) {
                const model = new this();
                model.id = modelData.id;
                model.attributes = modelData.attributes;
                model.relationships = modelData.relationships;
                model.meta = includedData.meta;
                model.included = includedData.included;
                return model;
            }

            static get camelCase() {
                return toCamelCase(this.type);
            }

            static get spaceCase() {
                return toSpaceCase(this.type);
            }

            static get dashCase() {
                return toDashCase(this.type);
            }

            static get paramName() {
                return this.dashCase;
            }

            static mapModel(response) {
                if (!response) {
                    log.debug('Tried to map a model to a null response', response);
                    return null;
                }
                if (Array.isArray(response.data)) {
                    if (!response.data.length) {
                        return null;
                    }
                    const result = [];
                    for (const data of response.data) {
                        result.push(this.createInstance(data.id, data.type, data.attributes, data.relationships, response.included, data.meta));
                    }
                    return result;
                }
                return this.createInstance(response.data.id, response.data.type, response.data.attributes, response.data.relationships, response.included, response.data.meta);
            }

            static createInstance(id, type, attributes, relationships, included, meta) {
                const instance = new this();
                instance.id = id;
                instance.type = type;
                instance.attributes = attributes;
                instance.relationships = relationships;
                instance.included = included;
                instance.meta = meta;
                return instance;
            }

            findRelated(relationship, model) {
                if (!this.included || !this.relationships[relationship] || !this.relationships[relationship].data || !this.relationships[relationship].data.id) {
                    return null;
                }
                const data = this.included.find((included) => {
                    return included.id == this.relationships[relationship].data.id && model.isType(included.type.toLowerCase());
                });
                if (!data) {
                    return null;
                }
                return model.createInstance(data.id, data.type, data.attributes, data.relationships, this.included, data.meta);
            }

            findAllRelated(relationships, model) {
                if (!Array.isArray(relationships)) {
                    relationships = [relationships];
                }
                for (const relationship of relationships) {
                    if (!this.included || !this.relationships || !this.relationships[relationship] || !this.relationships[relationship].data || !this.relationships[relationship].data.length) {
                        continue;
                    }
                    return this.included.filter((included) => {
                        return model.isType(included.type.toLowerCase()) && this.relationships[relationship].data.find(related => related.id == included.id && model.isType(related.type.toLowerCase()));
                    }).map((data) => {
                        return model.createInstance(data.id, data.type, data.attributes, data.relationships, this.included, data.meta);
                    });
                }
                return [];
            }
        };

        return BaseModel;
    },
]);
