app.factory('Store', [
    'BaseModel',
    'Product',
    'cart',
    function (
        BaseModel,
        Product,
        cart,
    ) {
        return class Store extends BaseModel {
            static isType(type) {
                return type == 'stores' || type == 'store';
            }

            get products() {
                if (!this._products) {
                    if (this.attributes.productCodes) {
                        this._products = cart.getPrintServiceProducts().filter((printServiceProduct) => {
                            return this.attributes.productCodes.indexOf(printServiceProduct.attributes.product_code) !== -1;
                        });
                    } else {
                        this._products = this.findAllRelated('products', Product);
                    }
                }
                return this._products;
            }

            hasCartProducts() {
                for (const productId of cart.lineItems.map(l => l.product.id)) {
                    if (!productId) {
                        continue;
                    }
                    if (!this.products.find(p => p.id == productId)) {
                        return false;
                    }
                }
                return true;
            }

            get printServiceId() {
                return this.attributes.print_service_id || this.relationships.printService.data.id;
            }

            createMapMarker() {
                // Hard code marker images because we don't have data to detect this currently
                let storeMarkerIcon = 'red';
                const markerIcons = [
                    'bartell',
                    'cvs',
                    'duane-reade',
                    'longs-drugs',
                    'navarro',
                    'target',
                    'walgreens-flag',
                    'walgreens',
                    'warehouse-stationery',
                ];
                for (const markerIcon of markerIcons) {
                    const normalizedName = markerIcon.replace(/-/g, ' ');
                    if (this.attributes.name.toLowerCase().indexOf(normalizedName) !== -1) {
                        storeMarkerIcon = markerIcon;
                        break;
                    }
                }
                const iconUrl = `images/markers/${storeMarkerIcon}.png`;

                return {
                    id: 'store-' + this.id,
                    latitude: this.attributes.latitude,
                    longitude: this.attributes.longitude,
                    iconUrl,
                    data: {
                        store: this,
                    },
                };
            }

            formatAddress() {
                const result = [];
                result.push(this.attributes.address || this.attributes.storeAddress1 || '');
                result.push('\n');
                result.push(this.attributes.city || this.attributes.storeCity || '');
                result.push(this.attributes.postcode || this.attributes.storePostCode || '');
                return result.filter(part => part != '').join(' ');
            }
        };
    },
]);
