app.factory('login', [
    'config',
    function (
        config,
    ) {
        return new class Login {
            constructor() {
                this.loginToken = config.storageGet('loginToken');
                this.isLoggedIn = !!this.loginToken;
                this.name = config.storageGet('loginName') || 'My Account';
            }

            login(loginToken, name) {
                this.isLoggedIn = true;

                config.storageSet('loginToken', loginToken);
                this.loginToken = loginToken;

                config.storageSet('loginName', name);
                this.name = name || 'My Account';
            }

            logout() {
                this.isLoggedIn = false;

                config.storageSet('loginToken', null);
                this.loginToken = null;

                config.storageSet('loginName', null);
                this.name = null;
            }

            setName(name) {
                config.storageSet('loginName', name);
                this.name = name || 'My Account';
            }

            check() {
                const loginToken = config.storageGet('loginToken');
                if (!loginToken) {
                    window.location = config.url('sign-in/');
                    return;
                }
                return loginToken;
            }
        };
    },
]);
