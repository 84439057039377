app.factory('imageUploader', [
    '$q',
    '$rootScope',
    'api',
    'config',
    'gtm',
    'log',
    'Upload',
    function (
        $q,
        $rootScope,
        api,
        config,
        gtm,
        log,
        Upload,
    ) {
        return new class ImageUploader {
            constructor() {
                this.uploads = [];
                this.uploadingCount = 0;
                this.s3Resource = api.gets3Resource();
            }

            addFile(file) {
                const defer = $q.defer();
                const upload = {
                    file: file,
                    progress: null,
                    image: null,
                    load: defer.promise,
                    defer: defer,
                };
                this.uploads.push(upload);
                this.startUpload();
                return upload;
            }

            startUpload() {
                log.verbose('Start upload', this.uploads, this.uploadingCount, config.maxUploadCount);
                if (this.uploads.length > 0 && this.uploadingCount < config.maxUploadCount) {
                    const upload = this.uploads.shift();
                    this.uploadingCount++;
                    setTimeout(() => {
                        this.upload(upload);
                    }, this.uploadingCount * 10);
                }
            }

            async upload(upload) {
                upload.progress = 0;
                upload.started = true;
                upload.startTime = new Date();

                log.verbose('Apply EXIF rotation', file);

                let file = null;
                try {
                    file = await Upload.applyExifRotation(upload.file);
                } catch (error) {
                    this.handleUploadError('Failed to apply rotation', error, upload, file, null);
                    return;
                }

                let s3 = null;
                try {
                    s3 = await this.s3Resource;
                } catch (error) {
                    this.handleUploadError('Failed to get S3 resource', error, upload, file, null);
                    return;
                }

                let hash = null;
                try {
                    hash = await this.getHash(file);
                } catch (error) {
                    this.handleUploadError('Failed to get file hash', error, upload, file, null);
                    return;
                }

                const extension = file.name.replace(/.*\./, '');
                const key = `${s3.location}${hash}-${file.size}.${extension}`;
                upload.convertedFileName = `${hash}-${file.size}.${extension}`;
                upload.remoteLink = 'https://' + s3.bucket + '.s3.amazonaws.com:443/' + key;
                upload.key = key;

                log.verbose('Uploading', file);
                Upload.upload({
                    url: 'https://' + s3.bucket + '.s3-accelerate.amazonaws.com:443/',
                    method: 'POST',
                    data: {
                        key: upload.key,
                        AWSAccessKeyId: s3.accessKeyId,
                        policy: s3.policy,
                        signature: s3.signature,
                        acl: 'public-read',
                        'Content-Type': file.type != '' ? file.type : 'application/octet-stream',
                        filename: upload.convertedFileName,
                        name: '',
                        file: file,
                    },
                }).then(async (response) => {
                    log.verbose('Upload complete', response);
                    upload.success = response;
                    upload.endTime = new Date();

                    try {
                        await this.register(upload, upload.remoteLink);
                    } catch (error) {
                        this.handleUploadError('Register image error', error, upload, file, null);
                        return;
                    }
                    log.verbose('Register image complete');
                    upload.defer.resolve();

                    this.uploadingCount--;
                    this.startUpload();

                    gtm.push({
                        event: 'Image uploaded',
                        time: upload.endTime - upload.startTime,
                    });
                }, (response) => {
                    this.handleUploadError('Upload failure', null, upload, file, response);
                }, (evt) => {
                    upload.progress = evt.loaded / evt.total;
                });
            }

            handleUploadError(message, error, upload, file, response) {
                log.debug('Failed to upload image', message, error, upload, file, response);
                log.error('Failed to upload image', message, error, upload.key ? upload.key : '(unknown key)', file && file.type ? file.type : '(unknown file type)');
                upload.error = 'Failed to upload image "' + (file && file.name ? file.name : 'unknown file name') + '", please try uploading again.';
                $rootScope.uploadErrors.push('Failed to upload image "' + (file && file.name ? file.name : 'unknown file name') + '", please try uploading again.');
                upload.defer.reject();
                this.uploadingCount--;
                this.startUpload();
            }

            async getHash(file) {
                return $q((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onerror = () => {
                        reject('Error reading file.');
                    };
                    reader.onabort = () => {
                        reject('Aborted reading file.');
                    };
                    reader.onloadend = (event) => {
                        if (event.target.readyState == FileReader.DONE) {
                            resolve(md5(event.target.result));
                        }
                    };
                    reader.readAsArrayBuffer(file);
                });
            }

            async register(upload, imageUrl) {
                return api.post('users/0/images', {
                    meta: {
                        device_token: config.deviceToken,
                    },
                    data: {
                        type: 'images',
                        attributes: {
                            external_url: imageUrl,
                        },
                    },
                }).then((response) => {
                    upload.image = response;
                });
            }
        };
    },
]);
