app.factory('Address', [
    'BaseModel',
    function (
        BaseModel,
    ) {
        return class Address extends BaseModel {
            static isType(type) {
                return type == 'addresses' || type == 'address';
            }

            static createInstance(id, type, attributes, relationships, included, meta) {
                return super.createInstance(id, type, attributes, relationships, included, meta);
            }
        };
    },
]);
