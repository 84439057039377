app.directive('pImagePreview', [
    'thumbnailer',
    function (
        thumbnailer,
    ) {
        return {
            restrict: 'A',
            replace: true,
            scope: {
                lineItem: '=pImagePreview',
            },
            link: function (scope, element) {
                scope.$watch('lineItem.thumbnail', (thumbnail) => {
                    if (!thumbnail || !thumbnail.canvas) {
                        return;
                    }
                    thumbnail.load.then(() => {
                        const context = element[0].getContext('2d');
                        element[0].width = 80;
                        element[0].height = 80;
                        thumbnailer.drawImageCover(context, thumbnail.canvas);
                    });
                });
            },
        };
    }]);
