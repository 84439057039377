app.directive('pClientNavMenu', [
    '$rootScope',
    'config',
    'apiv2',
    'login',
    'Unique',
    function (
        $rootScope,
        config,
        apiv2,
        login,
        Unique,
    ) {
        return {
            restrict: 'A',
            replace: true,
            scope: {
            },
            template: `
                <li class="nav-item dropdown p-client-nav-menu ml-lg-3">
                    <a
                        href=""
                        class="dropdown-toggle btn btn-default btn-sm mb-4 mb-lg-0 w-100 w-lg-auto p-client-nav-menu-dropdown-toggle"
                        data-toggle="dropdown"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <span ng-show="!login.isLoggedIn">Sign In</span>
                        <span ng-show="login.isLoggedIn">{{ login.name }}</span>
                        <span class="caret"></span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right p-client-nav-menu-guest p-4">
                        <span class="p-client-nav-menu-login-form-wrapper" ng-show="!login.isLoggedIn">
                            <h3 class="p-client-nav-menu-heading" ng-bind="'Signing In'" ng-show="loading"></h3>
                            <div class="progress" ng-show="loading">
                                <div class="progress-bar progress-bar-success progress-bar-striped progress-bar-animated active" style="width: 100%">
                                </div>
                            </div>
                            <form class="p-client-nav-menu-login-form" ng-submit="submit($event)" ng-show="!loading">
                                <h3 class="p-client-nav-menu-heading">Sign In</h3>
                                <p class="alert alert-danger" ng-show="formErrors">
                                    {{ formErrors | trustedHtml }}
                                </p>
                                <div class="form-group">
                                    <label for="p-user-email-{{ uid }}">Email address</label>
                                    <input
                                        id="p-user-email-{{ uid }}"
                                        class="form-control"
                                        type="email"
                                        ng-model="email"
                                        required />
                                    <div class="text-danger" ng-if="error.fields.data.attributes.emailAddress" ng-repeat="message in error.fields.data.attributes.emailAddress" ng-bind="message"></div>
                                </div>
                                <div class="form-group">
                                    <label for="p-user-password-{{ uid }}">Password</label>
                                    <input
                                        id="p-user-password-{{ uid }}"
                                        class="form-control"
                                        type="password"
                                        ng-model="password"
                                        required />
                                    <div class="text-danger" ng-if="error.fields.data.attributes.password" ng-repeat="message in error.fields.data.attributes.password" ng-bind="message"></div>
                                </div>
                                <div class="alert alert-danger" ng-bind="error.message" ng-if="error.message" ng-cloak></div>
                                <div class="form-group p-client-nav-menu-form-actions d-flex">
                                    <button
                                        type="submit"
                                        class="btn btn-primary p-client-nav-menu-btn-sign-in">
                                        Sign In
                                    </button>
                                    <div class="ml-auto pl-3 text-right">
                                        <a class="d-block p-nav-menu-register" href="register/">Register</a>
                                        <a class="d-block p-nav-menu-forgot-password" href="forgot-password/">Forgotten password?</a>
                                    </div>
                                </div>
                            </form>
                        </span>
                        <a ng-show="login.isLoggedIn" href="account/" class="dropdown-item p-nav-menu-my-account">My Account</a>
                        <a ng-show="login.isLoggedIn" href="account/orders/" class="dropdown-item p-nav-menu-my-orders">My Orders</a>
                        <a ng-show="login.isLoggedIn" ng-click="logout()" class="dropdown-item p-nav-menu-sign-out">Sign Out</a>
                    </span>
                </li>
            `,
            link: function (scope) {
                scope.uid = Unique.inc();
                scope.state = 'logged-out';
                scope.email = config.storageGet('email') || '';
                scope.loading = false;
                scope.error = null;
                scope.login = $rootScope.login;

                scope.submit = ($event) => {
                    $event.preventDefault();
                    scope.loading = true;
                    scope.error = null;
                    apiv2.post('client/account/login', {
                        data: {
                            type: 'AccountLogin',
                            attributes: {
                                emailAddress: scope.email,
                                password: scope.password,
                                deviceId: config.deviceToken,
                            },
                        },
                    }).then((response) => {
                        scope.loading = false;
                        login.login(response.data.attributes.loginToken, response.data.attributes.name);
                    }).catch((error) => {
                        scope.loading = false;
                        scope.error = error;
                    });
                };

                scope.logout = () => {
                    login.logout();
                    window.location = config.url('/');
                };
            },
        };
    },
]);
