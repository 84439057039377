app.factory('CartLineItemPage', [
    '$http',
    '$q',
    'log',
    function (
        $http,
        $q,
        log,
    ) {
        return class CartLineItemPage {
            constructor({ name, icon, template, templates, alwaysShowLayouts }) {
                this.name = name;
                this.icon = icon;
                this.template = template;
                this.templates = templates;
                this.alwaysShowLayouts = alwaysShowLayouts;
                this.svg = null;
                this.loading = false;
                this.data = {};
                this.regions = {};
            }

            load() {
                log.debug('Loading page template', this.template);

                return $q((resolve, reject) => {
                    if (this.template.svg) {
                        resolve(this.template.svg);
                        return;
                    }

                    this.loading = true;
                    $http.get(this.template.url).then((response) => {
                        this.loading = false;
                        this.template.svg = response.data;


                        resolve(this.template.svg);
                    }, (error) => {
                        this.loading = false;
                        log.error('Failed to load page template.', error);
                        reject(error);
                    });
                });
            }

            setTemplate(template) {
                this.template = template;

                return this.load();
            }

            getRegion(element) {
                const elementData = $(element).data('region');
                if (!this.regions[elementData.id]) {
                    this.regions[elementData.id] = elementData.input;
                }
                elementData.input = this.regions[elementData.id];
                return elementData;
            }

            get vertical() {
                return this.template.orientation == 'portrait';
            }
        };
    },
]);
