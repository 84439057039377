window.app = angular.module('app', [
    'ngFileUpload',
]);

app.run(['$window', '$q', function ($window, $q) {
    $window.Promise = $q;
}]);

app.config(['$provide', function ($provide) {
    $provide.decorator('$browser', ['$delegate', function ($delegate) {
        $delegate.onUrlChange = function () {
        };
        $delegate.url = function () {
            return '';
        };
        return $delegate;
    }]);
}]);

app.config(['$compileProvider', function ($compileProvider) {
    $compileProvider.debugInfoEnabled(CONFIG.debug);
}]);

window.TRANSPARENT_PIXEL = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

// Global jQuery bindings
$('body').tooltip({ selector: '[data-toggle="tooltip"]' });


const adjustCartSize = () => {
    $('.p-cart-images').width(Math.floor($('.p-cart-images').parent().width() / 90) * 90);
};
$(window).resize(adjustCartSize);
$(adjustCartSize);
adjustCartSize();


// temporary fix for blogs
$('.p-blog-list-single').on('click', evt => {
    evt.preventDefault();

    const href = $(evt.currentTarget).find('.p-blog-list-single-feature').attr('href');

    window.location.href = href;
});
