app.directive('pAppBanner', [
    '$templateCache',
    'browser',
    'config',
    'query',
    function (
        $templateCache,
        browser,
        config,
        query,
    ) {
        return {
            template: $templateCache.get('app-banner'),
            link: function (scope) {
                scope.isMobile = browser.isIos || browser.isAndroid;
                scope.isAndroid = browser.isAndroid || !!query.android;
                scope.isIos = browser.isIos || !!query.ios;
                scope.banner = {
                    visible: false,
                    brand: config.brandName,
                    url: null,
                };

                if (!query['no-app-banner']) {
                    if (scope.isIos && !config.disableAppleAppStoreMobileBanner && config.appleAppStoreUrl) {
                        scope.banner.url = config.appleAppStoreUrl;
                        scope.banner.visible = true;
                    }
                    if (scope.isAndroid && !config.disableGooglePlayMobileBanner && config.googlePlayUrl) {
                        scope.banner.url = config.googlePlayUrl;
                        scope.banner.visible = true;
                    }
                }

                scope.dismiss = () => {
                    scope.banner.visible = false;
                };

                scope.install = () => {
                    scope.banner.visible = false;
                    window.open(scope.banner.url);
                };
            },
        };
    },
]);
