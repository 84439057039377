app.directive('pCategories', [
    'log',
    'query',
    function (
        log,
        query,
    ) {
        return {
            restrict: 'A',
            scope: {
                categories: '=pCategories',
                change: '&pCategoriesChange',
            },
            template: `
                <div class="p-categories">
                    <button class="btn btn-primary p-category p-vert-mid" ng-repeat="category in categories" ng-click="toggleCategory(category)" ng-class="{'p-category-selected': category.selected}">
                        <span class="close">&times;</span>
                        <span>{{ category.name }}</span>
                    </button>
                </div>
            `,
            link: function (scope) {
                const cleanCategoryName = (categoryName) => {
                    return categoryName.toLowerCase().replace(/[^a-z0-9]/g, '');
                };

                if (query.categories) {
                    for (const queryCategory of query.categories.split(',')) {
                        const queryCategoryName = cleanCategoryName(queryCategory);
                        const category = scope.categories.find(c => cleanCategoryName(c.name) == queryCategoryName);
                        if (category) {
                            log.debug('Enabling category by query string', queryCategoryName, category);
                            category.selected = true;
                        } else {
                            log.debug('Could not find query category', queryCategoryName);
                        }
                    }
                }

                scope.toggleCategory = (category) => {
                    log.debug('Toggling category');
                    category.selected = !category.selected;
                    scope.change();
                };

                scope.change();
            },
        };
    },
]);
