app.factory('utils', [function () {
    return {
        getAspectRatio(width, height, seperator = ':') {
            const calc = (a, b) => (b == 0) ? a : calc(b, a % b);
            const ratio = calc(width, height);

            return (width / ratio) + seperator + (height / ratio);
        },

        indexOfSmallest(array) {
            return array.indexOf(Math.min.apply(Math, array));
        },

        cleanString (s) {
            /* eslint-disable-next-line */
            return s.replace(/[^\x00-\x7F]+/g, '');
        },
    };
}]);
