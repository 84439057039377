app.directive('pTemplatePreview', [
    'svg',
    function (
        svg,
    ) {
        return {
            restrict: 'A',
            scope: {
                svg: '=pTemplatePreview',
                samplePhoto: '=pTemplatePreviewSamplePhoto',
            },
            link: function (scope, element) {
                element
                    .addClass('p-template-preview')
                    .html(scope.svg);
                if (scope.samplePhoto) {
                    element.find('.p-template-image').each(function () {
                        const data = $(this).data('region');
                        const svgImage = svg('image')
                            .attr(data.box)
                            .attr('preserveAspectRatio', 'xMidYMid slice')
                            .appendTo(this);
                        svg.setImageUrl(svgImage, scope.samplePhoto);
                    });
                }
            },
        };
    },
]);
