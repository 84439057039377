app.directive('pModalFrameFlow', [
    '$rootScope',
    'cart',
    'svg',
    'frameHelpers',
    function (
        $rootScope,
        cart,
        svg,
        frameHelpers,
    ) {
        return {
            restrict: 'A',
            scope: {
            },
            template: window.appTemplates.FRAME_FLOW_MODAL,
            link: function (scope, element) {
                scope.lineItem = null;
                scope.file = null;
                scope.showProductsDraw = false;
                scope.frames = [];
                scope.products = [];

                scope.rotate = function () {
                    if (scope.lineItem.settings.rotate === 360) {
                        scope.lineItem.settings.rotate = 90;
                    } else {
                        scope.lineItem.settings.rotate += 90;
                    }

                    applyImageUpdates();
                };

                scope.rotateFrame = function (orientation) {
                    const frame = frameHelpers.getCurrentFrame(scope.lineItem, orientation);

                    if (! frame || frame.orientation == scope.lineItem.settings.orientation) {
                        return;
                    }

                    scope.lineItem.settings.orientation = frame.orientation;
                    wrapper.html(frameHelpers.render(scope.lineItem));
                };

                scope.reset = function () {
                    scope.lineItem.settings.x = scope.defaults.x;
                    scope.lineItem.settings.y = scope.defaults.y;
                    scope.lineItem.settings.width = scope.defaults.width;
                    scope.lineItem.settings.height = scope.defaults.height;
                    scope.lineItem.settings.scale = scope.defaults.scale;
                    scope.lineItem.settings.rotate = scope.defaults.rotate;
                    applyImageUpdates();
                };

                scope.close = function close() {
                    let frame = frameHelpers.getCurrentFrame(scope.lineItem);
                    let processedSvg = frameHelpers.extractPhotoSvg(wrapper.find('svg'), frameHelpers.getCurrentFrame(scope.lineItem));
                    processedSvg = processedSvg.replace(/\s+/g, ' ');
                    scope.lineItem.previewSvg = frameHelpers.render(scope.lineItem);
                    scope.lineItem.svg = processedSvg;
                    // FIX: make sure orientation is correct when sent to server
                    scope.lineItem.cropRectPos = {
                        orientation: frame.isPortrait ?  'portrait' : 'landscape',
                    };
                    $rootScope.closeModal();
                };

                scope.selectProduct = function (product) {
                    scope.lineItem.setProduct(product);
                    scope.frames = frameHelpers.getFrames(scope.lineItem);

                    wrapper.html(frameHelpers.render(scope.lineItem));
                };

                scope.$watch('lineItem.settings.scale', () => {
                    applyImageUpdates();
                });

                scope.$watch('file', file => {
                    if (!file) return;

                    const image = cart.addImage(file);

                    if (! image) {
                        return;
                    }

                    scope.lineItem.loading = true;
                    scope.lineItem.setImage(image);
                    image.thumbnail.load.then(() => {
                        scope.lineItem.loading = false;
                        svg.setImageUrl(wrapper.find('[data-element=photo]'), scope.lineItem.image.getSrc());
                        scope.reset();
                    });
                });

                $rootScope.$watch('modalFrameFlow', lineItem => {
                    if (! lineItem) {
                        scope.lineItem = null;
                        element.modal('hide');
                        return;
                    }

                    element.modal('show');
                    scope.products = getProducts(lineItem);
                    scope.frames = frameHelpers.getFrames(lineItem);
                    scope.defaults = frameHelpers.getPhotoDefaults(frameHelpers.getCurrentFrame(lineItem), lineItem.image);
                    scope.lineItem = lineItem;

                    wrapper.html(frameHelpers.render(lineItem));
                });

                let mouseDown, mouseX, mouseY, offset, wrapper;

                $(document).on('mouseup touchend', () => {
                    mouseDown = false;
                });
                wrapper = $('#p-frame-editor-wrapper');
                wrapper.on('mousedown touchstart', evt => {
                    if (('which' in evt && evt.which == 1) || evt.button == 1 || evt.originalEvent.touches[0]) {
                        if (evt.target.getAttribute('data-element') == 'photo') {
                            evt.preventDefault();
                            mouseDown = true;

                            offset = getMousePosition(evt);

                            offset.x -= scope.lineItem.settings.x;
                            offset.y -= scope.lineItem.settings.y;
                        }
                    }
                });
                wrapper.on('mousemove touchmove', evt => {
                    if (mouseDown) {
                        evt.preventDefault();
                        const { x, y } = getMousePosition(evt);

                        scope.lineItem.settings.x = (x - offset.x);
                        scope.lineItem.settings.y = (y - offset.y);

                        applyImageUpdates();
                    }
                });

                function getProducts (lineItem) {
                    return lineItem.getAvailableProducts().filter(product => {
                        return product.productImages.filter(img => img.metaData.frame).length > 0;
                    });
                }

                function applyImageUpdates() {
                    wrapper.find('svg [data-element=photo]').css({
                        transformOrigin: 'center center',
                        transformBox: 'fill-box',
                        transform: `
                            translate(${scope.lineItem.settings.x}px, ${scope.lineItem.settings.y}px)
                            scale(${scope.lineItem.settings.scale})
                            rotate(${scope.lineItem.settings.rotate}deg)
                        `,
                    });
                }

                function getMousePosition(evt) {
                    mouseX = evt.pageX || evt.originalEvent.touches[0].pageX;
                    mouseY = evt.pageY || evt.originalEvent.touches[0].pageY;

                    const CTM = wrapper.find('svg')[0].getScreenCTM();

                    return {
                        x: (mouseX - CTM.e) / CTM.a,
                        y: (mouseY - CTM.f) / CTM.d,
                    };
                }
            },
        };
    },
]);

