app.directive('pEditableInput', function () {
    return {
        restrict: 'EA',
        replace: true,
        scope: {
            model: '=pEditableInput',
            editing: '=?pEditableInputEditing',
            required: '=?pEditableInputRequired',
            label: '@?pEditableInputLabel',
            type: '@?pEditableInputType',
            inputId: '@?pEditableInputId',
        },
        template: `
            <div
                class="form-group p-editable-input"
                ng-class="{'p-editable-input-readonly': !editing}">
                <label
                    class="p-editable-input-label"
                    ng-if="label"
                    ng-attr-for="{{ inputId }}">
                    {{ label | trustedHtml }}
                </label>
                <input
                    class="form-control p-editable-input-field"
                    ng-model="model"
                    ng-attr-id="{{ inputId }}"
                    ng-attr-type="{{ type }}"
                    ng-required="required"
                    ng-readonly="!editing">
            </div>
        `,
        link: function ($scope) {
            $scope.inputId = $scope.inputId || Math.random().toString(36).substr(2, 9);
            $scope.type = $scope.type || 'text';
            if (typeof $scope.editing === 'undefined') {
                $scope.editing = false;
            }
            if (typeof $scope.required === 'undefined') {
                $scope.required = false;
            }
        },
    };
});
