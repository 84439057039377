app.factory('Order', [
    'BaseModel',
    'Address',
    'LineItem',
    'Store',
    function (
        BaseModel,
        Address,
        LineItem,
        Store,
    ) {
        return class Order extends BaseModel {
            static isType(type) {
                return type == 'orders' || type == 'order';
            }

            static createInstance(id, type, attributes, relationships, included, meta) {
                const order = super.createInstance(id, type, attributes, relationships, included, meta);
                order.store = order.findRelated('store', Store);
                order.address = order.findRelated('address', Address);
                order.lineItems = order.findAllRelated('lineItems', LineItem);
                order.attributes.total = parseFloat(order.attributes.total);
                order.attributes.subtotalDiscount = parseFloat(order.attributes.subtotalDiscount);
                order.attributes.freight = parseFloat(order.attributes.freight);
                order.attributes.freightDiscount = parseFloat(order.attributes.freightDiscount);
                return order;
            }
        };
    },
]);
