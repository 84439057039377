app.factory('log', [
    'exception',
    function (
        exception,
    ) {
        const logger = console;

        return new class Log {
            debug(...messages) {
                if (CONFIG.debug) {
                    logger.log('DEBUG:', ...messages);
                }
            }

            verbose(...messages) {
                if (CONFIG.verbose) {
                    logger.log('VERBOSE:', ...messages);
                }
            }

            error(message, ...details) {
                logger.error('ERROR:', message, ...details);

                if (message) {
                    exception.report(message, ...details);
                }
            }

            mapErrors(response) {
                if (response.message) {
                    return [response.message];
                }
                if (response.data && response.data.errors) {
                    return response.data.errors.map(error => error.title);
                }
                if (response.data && response.data.body && response.data.body.errors) {
                    return response.data.body.errors.map(error => error.title);
                }
                return [];
            }
        };
    },
]);
