app.factory('PrintService', [
    '$q',
    '$http',
    'api',
    'Product',
    'BaseModel',
    'log',
    function (
        $q,
        $http,
        api,
        Product,
        BaseModel,
        log,
    ) {
        return class PrintService extends BaseModel {
            static isType(type) {
                return type == 'print_services' || type == 'printservice';
            }

            static async findById(printServiceId, productTags = null) {
                const requestOptions = {
                    include: 'products.prices',
                };

                // Check for product tags
                if (productTags && productTags.length) {
                    productTags = productTags.map(productTag => productTag.name);
                    productTags.push('null');

                    requestOptions['filter[tags]'] = productTags.join(',');
                }

                const response = await api.get(
                    `clients/this/printServices/${printServiceId}`,
                    requestOptions
                );

                const printService = PrintService.mapModel(response);
                if (!printService) {
                    log.error('Could not find print service: ' + printServiceId);
                    return;
                }

                return printService;
            }

            get products() {
                if (!this._products) {
                    this._products = this.findAllRelated('products', Product);
                }
                return this._products;
            }
        };
    },
]);
