app.factory('CartImage', [
    function (
    ) {
        return class CartImage {
            constructor(key, file, upload, thumbnail) {
                this.key = key;
                this.file = file;
                this.upload = upload;
                this.thumbnail = thumbnail;
            }

            isReady() {
                if (this.upload.image && this.upload.image.data.id) {
                    return true;
                }
                return false;
            }

            isStarted() {
                return this.upload.started;
            }

            isErrored() {
                return this.thumbnail.error || this.upload.error;
            }

            getProgress() {
                return this.upload.progress;
            }

            isCanvas() {
                return this.thumbnail.canvas ? true : false;
            }

            getSrc() {
                if (!this.thumbnail.image) {
                    return null;
                }
                return this.thumbnail.image.src;
            }

            getWidth () {
                if (this.thumbnail && this.thumbnail.image) {
                    return this.thumbnail.image.width || 0;
                }

                return 0;
            }

            getHeight () {
                if (this.thumbnail && this.thumbnail.image) {
                    return this.thumbnail.image.height || 0;
                }

                return 0;
            }

            getImageId() {
                return this.upload.image.data.id;
            }

            getType() {
                if (this.file && this.file.type) {
                    return this.file.type;
                }
                return 'image/jpeg';
            }

            canEnlarge() {
                return this.isCanvas() || this.getSrc();
            }

            getOrientation () {
                return this.getWidth() > this.getHeight() ? 'landscape' : 'portrait';
            }
        };
    },
]);
